import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '@infra/http-client';
import { notify } from '@src/utils/notification';
import { textToClipboard } from '@src/utils/utils';
import { filterStatus } from '@src/utils/enum/filter';

const client = HttpClient('');
const clientJourney = HttpClient('');

const filterSwitch = (type) => {
  return (
    {
      flowType: 'fluxoIds',
      startDate: 'inicio',
      endDate: 'fim',
      status: 'listaStatus',
      document: 'documentos',
      protocol: 'protocolos',
    }[type] || []
  );
};

const mountFilter = (filter) => {
  const { content, pagination, id } = filter;
  const transformFilter: any = {};
  Object.keys(content).forEach((igKey) => {
    const attr = filterSwitch(igKey);
    const value = content[igKey];

    if (value && (attr === filterStatus.START || attr === filterStatus.END)) {
      transformFilter[attr] = value.toISOString();
      return;
    }

    if (value && attr === filterStatus.STATUS) {
      const selectStatus = value.filter((e) => e.checked);
      if (selectStatus.length === 0) {
        transformFilter[attr] = value.map((e) => e.id);
        return;
      }
      transformFilter[attr] = selectStatus.map((e) => e.id);
      return;
    }

    if (
      value &&
      (attr === filterStatus.DOCUMENT ||
        filterStatus.FLOW ||
        filterStatus.PROTOCOL)
    ) {
      transformFilter[attr] = [value];
      return;
    }

    if (value) transformFilter[attr] = value === '' ? null : value;
  });
  transformFilter.index = pagination.page === 0 ? 1 : pagination.page;
  transformFilter.size = pagination.rowsPerPage;
  if (id) transformFilter.listaEnvioId = id;
  transformFilter.statusPedido =
    content.requestType === 0 ? 'abertos' : 'fechados';
  return transformFilter;
};

export const fetchRequests = createAsyncThunk<any, any>(
  'fetchRequests',
  async ({ filter, loading = true, id }, { getState }) => {
    filter.pagination = (getState() as any).requests.pagination;
    filter.id = id;
    const content: any = mountFilter(filter);
    const result = await client.get<any>({
      action: 'pedidos/filtrar',
      params: content,
      loading: loading,
    });

    if (result.status === 200) {
      const requests = result.data.pedidos.map((request) => {
        const result = {
          id: request.id,
          protocol: request.protocolo,
          date: request.dataHora,
          document: request.documento,
          flowTypeText: request.fluxo,
          statusText: request.status,
          status: request.status,
          details: 'Visualizar',
          positiveParts: request.partesPositivas,
          totalParts: request.totalPartes,
        };
        return result;
      });

      return {
        requests: requests,
        pagination: {
          page: content.index,
          rowsPerPage: content.size,
          count: result.data.total,
        },
      };
    }

    return [];
  }
);

const getLink = async (protocol) => {
  const response = await client.get<any>({
    action: 'partes/gerar-link',
    params: { protocolo: protocol },
    loading: true,
  });
  return { link: response.data.url };
};

export const copyLink = createAsyncThunk<any, any>(
  'copyLink',
  async (protocol) => {
    const result = await getLink(protocol);
    return result;
  }
);

export const sendLink = createAsyncThunk<any, any>(
  'sendLink',
  async (protocol) => {
    const result = await getLink(protocol);
    return result;
  }
);

export const fetchFlowCombo = createAsyncThunk(
  'fetchFlowCombo',
  async (history) => {
    const index = 1;
    const size = 100;
    const result = await clientJourney.get<any>({
      params: { index, size },
      loading: false,
      history,
      action: 'fluxos/ativos',
    });
    const requests = result.data.jornadas
      .map((request) => {
        return {
          id: request.id,
          name: request.nome,
          hasMultipleAccepts: request.configuracao?.funcionalidades.some(
            (x) => x.tipo === 'multiplos-aceites' && x.habilitado
          ),
          hasProposalArtifact: request.possuiProposta,
        };
      })
      .filter(
        (request) => request.id !== '649e464b-6768-4cdc-8105-46a65ebed43d'
      )
      .sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

    return requests;
  }
);

export const fetchProfiles = createAsyncThunk(
  'fetchProfiles',
  async (history) => {
    const result = await clientJourney.get<any>({
      action: 'perfis-assinatura',
      loading: false,
    });

    if (result.data) {
      const profiles = result.data.perfis;
      const converterProfiles = profiles.map((profile) => {
        const { id, nome } = profile;
        const result = { id, name: nome, fields: convertField(profile) };
        return result;
      });
      return converterProfiles;
    }

    return null;
  }
);

const convertField = (profile) => {
  const { campos } = profile;
  const result = campos.map((field) => {
    const {
      id,
      descricao,
      etiqueta,
      nome,
      tamanhoMaximo,
      obrigatorio,
      tipo,
      validacao,
      valor,
    } = field;
    return {
      id,
      description: descricao,
      label: etiqueta,
      name: nome,
      maxLength: tamanhoMaximo,
      required: obrigatorio,
      type: tipo,
      mask: validacao,
      value: valor,
    };
  });
  return result;
};

export const fetchStatus = createAsyncThunk('fetchStatus', async (type) => {
  const request = await client.get<any>({
    action: `partes/listar-grupo-status`,
    params: { grupo: type },
  });
  if (request.status === 200) {
    const result = request.data.listagem.map((status) => ({
      ...status,
      checked: false,
    }));
    return result;
  }
  return [];
});

export const resend = createAsyncThunk<any, any>(
  'resend',
  async (requestId) => {
    const request = await client.put({
      loading: true,
      action: `partes/reenviar`,
      body: { parteId: requestId },
    });
    return request;
  }
);

export const fetchStatusGroupCount = createAsyncThunk<any, any>(
  'fetchStatusGroupCount',
  async ({ type, id }) => {
    const result: any = { grupo: type };
    if (id) result.listaEnvioId = id;

    const request = await client.get<any>({
      action: `partes/contador-grupo-status`,
      params: result,
      loading: false,
    });

    if (request.status === 200) {
      const result = request.data.contadores.map((group) => ({
        count: group.total,
        label: group.status,
        id: group.statusId,
      }));
      return result;
    }
    return [];
  }
);

export const updateStatusRequest = createAsyncThunk<any, any>(
  'updateStatusRequest',
  async ({ id, action }) => {
    if (!id) return;
    const result = await client.put({
      action: action,
      body: { parteId: id },
      loading: true,
    });
    return result.data;
  }
);

export const requestsSlice = createSlice({
  name: 'requests',
  initialState: {
    pagination: { page: 0, rowsPerPage: 10, count: 0 },
    profiles: [],
    link: '',
    protocol: '',
    pedidoId: '',
    statusCombo: [],
  },
  reducers: {
    changePagination: (state, action) => {
      const { pagination } = action.payload;
      state.pagination = pagination;
    },
    clearLink: (state) => {
      state.link = '';
      state.statusCombo = [];
    },
    resetPaginationIndex: (state) => {
      state.pagination.page = 1;
    },
    setProtocolo: (state, action) => {
      state.protocol = action.payload;
    },
    setPedidoId: (state, action) => {
      state.pedidoId = action.payload;
    },
    clearProtocol: (state) => {
      state.protocol = '';
      state.statusCombo = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequests.fulfilled, (state, action) => {
      state.pagination = action.payload.pagination;
    });
    builder.addCase(fetchProfiles.fulfilled, (state, action) => {
      state.profiles = action.payload;
    });
    builder.addCase(sendLink.fulfilled, (state, action) => {
      const { link } = action.payload;
      state.link = link;
    });
    builder.addCase(copyLink.fulfilled, (state, action) => {
      const { link } = action.payload;
      textToClipboard(link);
      notify('Link copiado com sucesso!');
    });
    builder.addCase(resend.fulfilled, (state, action) => {
      notify('Solicitação reenviada com sucesso.');
    });
    builder.addCase(resend.rejected, (state, action) => {
      notify('Ocorreu um erro ao reenviar.');
    });
  },
});

export const {
  changePagination,
  clearLink,
  resetPaginationIndex,
  setProtocolo,
  setPedidoId,
  clearProtocol,
} = requestsSlice.actions;

export const selectLink = (state) => state.requests.link;
export const selectProtocol = (state) => state.requests.protocol;
export const selectPedidoId = (state) => state.requests.pedidoId;
export const selectPagination = (state) => state.requests.pagination;

export default requestsSlice.reducer;
