import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const reset = createAsyncThunk('reset', async () => {
  return { type: 'RESET' };
});

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {},
  reducers: {},
});

export default authenticationSlice.reducer;
