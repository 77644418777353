import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import HttpClientAnonymous from '@infra/http-client/anonymous';
import HttpClient from '@infra/http-client';
import { notify } from '@src/utils/notification';
import ArtifactFormModel from '@pages/Builder/models/artifact-form.model';
import Collect, { TypeCollect } from '@pages/Builder/models/collect.model';
import ComboBoxModel from '@src/models/combo-box';
import { GlobalStore } from '@src/models/stores/global';
import Builder, { IFunctionalitiesGroup } from '@models/stores/builder';
import { uploadJson } from '../upload';
import { ErrorHandled } from '@src/models/stores/error-handled';
import { convertJson, convertFunctionalities, FlowConvertor } from './utils';
import { ArtifactItemEnum } from '@src/utils/enum/artifact';
import FlowDraft from '@src/models/stores/flow-draft';
import { TemplateList } from '@src/pages/Builder/models/template-list-model';
import { ICollectResponse } from './interfaces/collect-response';
import { IFunctionalityResponse } from './interfaces/functionality-response';
import { IFlow } from './interfaces/flow';
import { ListTemplateResponse } from './interfaces/list-template-response';
import {
  DraftInformation,
  GetDraftResponse,
} from './interfaces/get-draft-response';
import { nanoid } from '@src/utils/custom-nanoid';
import { IGalleryColor } from '@src/models/stores/builder/builder-gallery-color';
import { IGalleryResponse } from './interfaces/gallery-response';
import { ILinkResponse } from './interfaces/link-respose';
import FileState from '@models/components/file';
import axios from 'axios';
import { UploadDraft } from './interfaces/upload-draft';
import { IBuilderFlow } from '@src/pages/Builder/models/flow-list';
import { IFlowListFilter } from './interfaces/flow-filter';
import { FlowListResponse } from './interfaces/flow-list-response';
import { StatusFlowEnum } from '@src/utils/enum/status-flow';

const clientCollections = HttpClientAnonymous('coletas');
const clientEmail = HttpClientAnonymous('email');
const clientEmpty = HttpClient('');
const clientFlow = HttpClient('fluxos');

const initialState: Builder = {
  typesCapture: [],
  colors: [{ primary: '#79259E', secondary: '#C94D97', id: nanoid() }],
  draft: { name: '', id: '' },
  templateId: undefined,
};

interface ArtifactEmail {
  form: ArtifactFormModel;
  token: string;
  onUpload: (progress: number) => void;
}

interface CreateNewDraft {
  name: string;
  templateId: string;
}

const convertFlow = (form: ArtifactFormModel) => {
  const {
    artifacts: artifactForm,
    name: nome,
    color: cor,
    document: cnpj,
    phone: telefone,
  } = form;

  const artifacts = artifactForm?.filter((artifact) => !artifact.fatherId);

  const artefatos = artifacts?.map((artifact, index) => {
    const convertion = convertJson[artifact.name];

    if (convertion) {
      if (
        [
          ArtifactItemEnum.CaptureDocument,
          ArtifactItemEnum.CaptureFace,
          ArtifactItemEnum.CaptureProof,
          ArtifactItemEnum.Proposal,
        ].includes(artifact.name)
      ) {
        const artifactsWithSubArtifact = artifactForm?.filter(
          (e) => e.id === artifact.id || e.fatherId === artifact.id
        );
        return convertion(artifactsWithSubArtifact, index);
      }

      return convertion(artifact.value, index);
    }
    return {};
  });
  const fluxo = { nome, cor, cnpj, telefone, artefatos };
  return fluxo;
};

export const sendEmail = createAsyncThunk<
  void,
  ArtifactEmail,
  { rejectValue: ErrorHandled }
>(
  'construtor/enviar-formulario',
  async (
    { form, token, onUpload },
    { dispatch, getState, rejectWithValue }
  ) => {
    const fluxo = convertFlow(form);
    const result = await dispatch(
      uploadJson({ object: fluxo, onUpload, token })
    );
    const state = getState() as GlobalStore;

    if (result?.payload && !(result.payload as ErrorHandled).errorMessage) {
      await clientEmail.post({
        action: 'enviar-formulario-builder',
        body: { id: result.payload, recaptchaResponse: token },
        loading: false,
      });

      await clientFlow.post({
        body: { chaveS3: result.payload, rascunhoId: state.builder.draft.id },
      });
    } else {
      return rejectWithValue({
        errorMessage: 'Ocorreu um erro ao enviar o e-mail',
      });
    }
  }
);

export const persistFlow = createAsyncThunk<
  void,
  IFlow,
  { rejectValue: ErrorHandled }
>(
  'construtor/enviar-formulario',
  async (
    { name, color, artifacts, onUpload },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as GlobalStore;
    const functionalities = state.builder.functionalities;
    const allowed = functionalities?.artifact.reduce((array: string[], el) => {
      if (el.allowedArtifacts) return [...array, ...el.allowedArtifacts];
      return array;
    }, []);

    try {
      const fluxo = FlowConvertor()
        .forArtifacts(artifacts)
        .withColor(color)
        .withName(name)
        .withFunctionalities({
          all: functionalities,
          allowedArtifacts: allowed,
        })
        .build();

      const result = await dispatch(
        uploadJson({ object: fluxo, onUpload, token: '' })
      );
      if (result?.payload && !(result.payload as ErrorHandled).errorMessage) {
        await clientFlow.post({
          body: { chaveS3: result.payload, rascunhoId: state.builder.draft.id },
        });
      } else {
        return rejectWithValue({
          errorMessage: 'Ocorreu um erro ao criar o fluxo',
        });
      }
    } catch (error) {
      return rejectWithValue({
        errorMessage: 'Ocorreu um erro ao criar o fluxo',
      });
    }
  }
);

export const fetchCaptures = createAsyncThunk<
  Collect[],
  string,
  { rejectValue: ErrorHandled }
>('construtor/obter-capturas', async (type, { rejectWithValue }) => {
  const response = await clientCollections.get<{
    documentos: ICollectResponse[];
  }>({
    action: 'obter-tipos-capturas',
    loading: false,
    params: { tipo: type },
  });
  if (response.data && response.status === 200) {
    const { documentos } = response.data;
    const result = documentos.map<Collect>((documento) => {
      const { descricao: description, capturas } = documento;
      const types = capturas.map<TypeCollect>((captura) => {
        const { tipo: type, titulo: title } = captura;
        return { type, title };
      });
      return { description, types };
    });
    return result;
  }
  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar os tipos de coleta.`,
  });
});

export const createPack = createAsyncThunk<
  IGalleryColor,
  IGalleryColor,
  { rejectValue: ErrorHandled }
>(
  'construtor/criar-pack',
  async ({ primary, secondary }, { rejectWithValue, dispatch }) => {
    const response = await clientEmpty.post({
      action: 'galeria/criar-pack',
      body: { corPrimaria: primary, corSecundaria: secondary },
      loading: true,
    });
    if (!response.data || response.status !== 201) {
      return rejectWithValue({
        errorMessage: `Ocorreu um erro ao criar o pack.`,
      });
    }
    return { id: nanoid(), primary, secondary };
  }
);

export const fetchPacks = createAsyncThunk<
  IGalleryColor[],
  void,
  { rejectValue: ErrorHandled }
>('construtor/obter-packs', async (_, { rejectWithValue, dispatch }) => {
  const response = await clientEmpty.get<{
    packs: IGalleryResponse[];
  }>({
    action: 'galeria/obter-packs',
    loading: false,
  });
  if (response.data && response.status === 200) {
    const { packs } = response.data;
    const result = packs.map<IGalleryColor>((cor) => {
      const { id, corPrimaria, corSecundaria } = cor;
      return { id, primary: corPrimaria, secondary: corSecundaria };
    });
    return result;
  }
  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar os packs.`,
  });
});

export const fetchLinkUploadDraft = createAsyncThunk<
  string,
  string,
  { rejectValue: ErrorHandled }
>('construtor/obter-link-upload-estrutura', async (id, { rejectWithValue }) => {
  const response = await clientEmpty.get<{
    link: string;
  }>({
    action: 'rascunhos/obter-link-upload-estrutura',
    params: { rascunhoId: id },
    loading: false,
  });
  if (response.data && response.status === 200) {
    const { link } = response.data;
    return link;
  }
  return rejectWithValue({
    errorMessage: `Ocorreu um erro o link da estrutura.`,
  });
});

export const uploadDraft = createAsyncThunk<
  void,
  UploadDraft,
  { rejectValue: ErrorHandled }
>(
  'construtor/upload-rascunho',
  async (
    { structure, theme, link, metric },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as GlobalStore;
    const { id, name } = state.builder.draft;
    if (id && name) {
      const estrutura = structure
        .filter((artifact) => artifact.name !== ArtifactItemEnum.Blank)
        .map((artifact) => {
          const { id, fatherId, name, value, isValid } = artifact;
          return { id, fatherId, name, value, isValid };
        });
      const response = await axios.put(
        link,
        {
          id,
          nome: name,
          estrutura,
          tema: { corPrimaria: theme, corDestaque: theme },
          metrica: metric,
        },
        {
          headers: {
            'Content-Type': '',
          },
        }
      );
      if (response.status === 201) {
        return;
      } else if (response.status === 403) {
        const newLink = await dispatch(fetchLinkUploadDraft(id));
        if (newLink.payload) {
          if (typeof newLink.payload === 'string')
            dispatch(
              uploadDraft({
                structure,
                theme,
                link: newLink.payload,
                metric,
              })
            );
        }
      }
      return rejectWithValue({
        errorMessage: `Ocorreu um erro ao realizar o upload do link da estrutura.`,
      });
    }

    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao realizar o upload do link da estrutura.`,
    });
  }
);

export const createDraft = createAsyncThunk<
  string,
  CreateNewDraft,
  { rejectValue: ErrorHandled }
>(
  'construtor/criar-rascunho',
  async ({ name, templateId }, { rejectWithValue, dispatch }) => {
    const response = await clientEmpty.post({
      action: 'rascunhos',
      body: { nome: name, templateId: templateId ?? null },
      loading: true,
    });

    if (!response.data || response.status !== 200) {
      return rejectWithValue({
        errorMessage: `Ocorreu um erro ao buscar os tipos de coleta.`,
      });
    }
    const { rascunhoId } = response.data;
    dispatch(addDraft({ name: name, id: rascunhoId }));

    return rascunhoId;
  }
);

export const deleteDraft = createAsyncThunk<
  void,
  string,
  { rejectValue: ErrorHandled }
>('construtor/deletar-rascunho', async (id, { dispatch, rejectWithValue }) => {
  const result = await clientEmpty.delete({
    action: 'rascunhos/remover',
    loading: true,
    params: { rascunhoId: id },
  });

  if (!result || ![204, 200].includes(result?.status)) {
    return rejectWithValue({
      errorMessage: 'Ocorreu um erro ao remover o fluxo',
    });
  }
});

export const fetchTemplates = createAsyncThunk<
  TemplateList[],
  void,
  { rejectValue: ErrorHandled }
>('construtor/buscar-templates', async (_, { rejectWithValue }) => {
  const response = await clientEmpty.get<ListTemplateResponse>({
    action: 'fluxos/listar-templates',
  });
  if (!response.data || response.status !== 200) {
    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar os templates.`,
    });
  }

  return response.data.templates.map((template) => {
    return {
      name: template.nome,
      id: template.id,
      firstArtifact: template.primeiroArtefatoJson,
    };
  });
});

export const fetchFlows = createAsyncThunk<
  IBuilderFlow[],
  IFlowListFilter,
  { rejectValue: ErrorHandled }
>('construtor/buscar-fluxos', async (filter, { rejectWithValue }) => {
  const { status } = filter;
  const response = await clientEmpty.get<{ fluxos: FlowListResponse[] }>({
    action: 'fluxos/filtrar',
    params: { status },
  });

  if (!response.data || response.status !== 200) {
    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar os fluxos.`,
    });
  }

  return response.data.fluxos
    .map<IBuilderFlow>((flow) => {
      const { id, nome, status, statusLabel, dataCriacao } = flow;
      return {
        name: nome,
        id: id,
        status: status as StatusFlowEnum,
        labelStatus: statusLabel,
        createDate: dataCriacao,
      };
    })
    .filter((flow) => flow.id !== '649e464b-6768-4cdc-8105-46a65ebed43d');
});

export const fetchDraft = createAsyncThunk<
  DraftInformation,
  string,
  { rejectValue: ErrorHandled }
>('construtor/buscar-rascunho', async (id, { rejectWithValue, dispatch }) => {
  const response = await clientEmpty.get<GetDraftResponse>({
    action: 'rascunhos/obter-rascunho',
    params: { rascunhoId: id },
  });
  if (!response.data || response.status !== 200) {
    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar os templates.`,
    });
  }

  dispatch(addDraft({ name: response.data.nome, id: response.data.id }));

  return {
    name: response.data.nome,
    id: response.data.id,
    theme: response.data?.tema?.corPrimaria,
    structure: response.data.estrutura,
    metric: { timeUsed: response.data?.metrica?.tempoUtilizado ?? 0 },
    templateId: response.data?.templateId,
  };
});

export const fetchFlow = createAsyncThunk<
  DraftInformation,
  string,
  { rejectValue: ErrorHandled }
>('construtor/buscar-fluxo', async (id, { rejectWithValue, dispatch }) => {
  const response = await clientEmpty.get<GetDraftResponse>({
    action: 'fluxos/estrutura',
    params: { fluxoId: id },
  });
  if (!response.data || response.status !== 200) {
    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar os templates.`,
    });
  }

  dispatch(addDraft({ name: response.data.nome, id: response.data.id }));

  return {
    name: response.data.nome,
    id: response.data.id,
    theme: response.data?.tema?.corPrimaria,
    structure: response.data.estrutura,
    metric: { timeUsed: 0 },
  };
});

export const fetchFunctionalities = createAsyncThunk<
  IFunctionalitiesGroup | undefined,
  void,
  { rejectValue: ErrorHandled }
>('construtor/funcionalidades', async (_, { rejectWithValue }) => {
  const response = await clientEmpty.get<{
    funcionalidadesMobile: IFunctionalityResponse[];
    funcionalidadesFluxo: IFunctionalityResponse[];
    funcionalidadesArtefatos: IFunctionalityResponse[];
  }>({
    action: 'fluxos/funcionalidades',
    loading: false,
  });

  if (response.data && response.status === 200) {
    const {
      funcionalidadesArtefatos,
      funcionalidadesFluxo,
      funcionalidadesMobile,
    } = response.data;

    const artifact = convertFunctionalities(funcionalidadesArtefatos);
    const flow = convertFunctionalities(funcionalidadesFluxo);
    const mobile = convertFunctionalities(funcionalidadesMobile);
    return { artifact, flow, mobile };
  }

  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar as funcionalidades.`,
  });
});

export const uploadImageBuilder = createAsyncThunk<
  string,
  { file: File; extension: string },
  { rejectValue: ErrorHandled }
>(
  'construtor/upload-imagem-builder',
  async ({ file, extension }, { rejectWithValue }) => {
    const response = await clientEmpty.get<{ link: ILinkResponse }>({
      action: 'galeria/obter-link-upload',
      loading: false,
      params: { extensao: extension },
    });
    if (response.data && response.status === 200) {
      const { link } = response.data;
      const responseUpload = await axios.put(link.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      if (responseUpload.status === 200) {
        const responsePersist = await clientEmpty.post({
          action: 'galeria/persistir-imagem-customizada',
          loading: false,
          body: { chaveS3: link.chave, extensao: extension },
        });
        if (responsePersist.status === 201) {
          return responsePersist.data.id;
        }
      } else
        return rejectWithValue({
          errorMessage: `Ocorreu um erro ao realizar o upload.`,
        });
    }

    return rejectWithValue({
      errorMessage: `Ocorreu um erro ao buscar as funcionalidades.`,
    });
  }
);

export const fetchImagens = createAsyncThunk<
  FileState[],
  void,
  { rejectValue: ErrorHandled }
>('construtor/upload-imagens-builder', async (_, { rejectWithValue }) => {
  const response = await clientEmpty.get<{ imagens: ILinkResponse[] }>({
    action: 'galeria/obter-imagens',
    loading: false,
  });
  if (response.data && response.status === 200) {
    const { imagens } = response.data;

    const files = imagens.map<FileState>((item) => {
      return {
        id: item.chave,
        extension: 'svg',
        name: `${item.chave}.svg`,
        isDefault: false,
        mimeType: item.mimeType,
        url: item.url,
      };
    });
    return files;
  }

  return rejectWithValue({
    errorMessage: `Ocorreu um erro ao buscar as imagens.`,
  });
});

export const builderSlice = createSlice({
  name: 'builder',
  initialState: { ...initialState },
  reducers: {
    addTypeCapture: (state, action: PayloadAction<ComboBoxModel>) => {
      const { id, name } = action.payload;
      const typeIndex = state.typesCapture.findIndex((type) => type.id === id);

      if (typeIndex >= 0) {
        state.typesCapture[typeIndex].name = name;
      } else {
        state.typesCapture.push({ id, name });
      }
    },
    addDraft: (state, action: PayloadAction<FlowDraft>) => {
      state.draft = action.payload;
    },
    disableFunctionalities: (
      state,
      action: PayloadAction<ArtifactItemEnum>
    ) => {
      if (state.functionalities?.artifact) {
        state.functionalities.artifact = state.functionalities.artifact.map(
          (artifact) => {
            if (
              artifact.allowedArtifacts &&
              artifact.allowedArtifacts.includes(action.payload)
            ) {
              return { ...artifact, able: false };
            }
            return artifact;
          }
        );
      }
    },
    setTemplateId: (state, action: PayloadAction<string | undefined>) => {
      state.templateId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(persistFlow.fulfilled, (state, { payload }) => {
      notify('Fluxo criado com sucesso.');
    });
    builder.addCase(fetchFunctionalities.fulfilled, (state, { payload }) => {
      if (payload) state.functionalities = payload;
    });
    builder.addCase(persistFlow.rejected, (state, { payload, error }) => {
      notify(payload?.errorMessage ?? 'Ocorreu um erro inesperado');
    });
    builder.addCase(fetchCaptures.rejected, (state, { payload, error }) => {
      notify(payload?.errorMessage ?? 'Ocorreu um erro inesperado');
    });
    builder.addCase(fetchPacks.fulfilled, (state, { payload }) => {
      if (payload) state.colors = state.colors.concat(payload).reverse();
    });
    builder.addCase(deleteDraft.fulfilled, () => {
      notify('O rascunho foi excluído com sucesso!');
    });
    builder.addCase(createPack.fulfilled, (state, { payload }) => {
      if (payload) {
        state.colors.splice(0, 0, payload);
      }
    });
  },
});

export const {
  addTypeCapture,
  addDraft,
  disableFunctionalities,
  setTemplateId,
} = builderSlice.actions;

export const selectTypesCapture = (state: GlobalStore) =>
  state.builder.typesCapture;

export const selectArtifactFunctionalities = (
  state: GlobalStore,
  type: string
) => {
  const functionalities = state.builder.functionalities;
  if (functionalities) {
    const result = functionalities.artifact.filter(
      (artifact) =>
        artifact.allowedArtifacts && artifact.allowedArtifacts.includes(type)
    );
    return result;
  }
};
export default builderSlice.reducer;

export const selectDraftInfo = (state: GlobalStore) => state.builder.draft;

export const selectGalleryColors = (state: GlobalStore) => state.builder.colors;

export const selectTemplateId = (state: GlobalStore) =>
  state.builder.templateId;
