import { createSlice } from '@reduxjs/toolkit';
import { GlobalStore } from '@src/models/stores/global';
import { HashValidatorModel } from '@src/models/stores/hash-validator';

export const hashValidatorSlice = createSlice({
  name: 'hash-validator',
  initialState: {
    authenticatorCode: '',
    checkerCode: '',
    signatures: [],
  } as HashValidatorModel,
  reducers: {
    setAuthenticatorCode: (state, action) => {
      state.authenticatorCode = action.payload;
    },
    setCheckerCode: (state, action) => {
      state.checkerCode = action.payload;
    },
    setSignatures: (state, action) => {
      state.signatures = action.payload;
    },
  },
});

export const { setAuthenticatorCode, setCheckerCode, setSignatures } =
  hashValidatorSlice.actions;

export const selectAuthenticatorCode = (state: GlobalStore) =>
  state.hashValidator.authenticatorCode;

export const selectCheckerCode = (state: GlobalStore) =>
  state.hashValidator.checkerCode;

export const selectSignatures = (state: GlobalStore) =>
  state.hashValidator.signatures;

export default hashValidatorSlice.reducer;
