import CollectedDocuments from '@src/models/components/collected-documents';
import FileModel from '@src/models/components/file';
import { DynamicField } from '@src/models/dynamic-field';
import Signature from '@pages/ValidationRequests/components/RequestDataTab/models/signature.model';

export enum ScoreClassification {
  Null,
  Lowest,
  Low,
  Average,
  High,
  Error,
}

export enum ScoreSectionType {
  ProofLife = 'provaVida',
  FacialBiometric = 'biometriaFacial',
  Document = 'documento',
}

export enum ScoreSectionTitle {
  ProofLife = 'Prova de vida',
  FacialBiometric = 'Biometria facial',
  Document = 'Documento',
}

export enum MatchStatus {
  Success = 'sucesso',
  Fail = 'falha',
  Info = 'info',
}

export interface RequestInitialState {
  requestDetail: RequestDetail;
  requestResponse: RequestResponse;
}

export interface RequestDetail {
  requestId: string;
  tutorialURL: string;
  tutorialDownloadURL: string;
  parts: RequestDetailPart[];
}

export interface RequestDetailPart {
  id: string;
  protocol: number;
  status: string;
  collected: boolean;
  attachedDocuments: FileModel[];
  CustomerData: CustomerData;
}

export interface CustomerData {
  nome: string;
  campos: DynamicField[];
}

export interface ChipLocalize {
  label: string;
  color: string;
  description?: string;
}
export interface ILocalizeSearchFields {
  name: string;
  label: string;
  value: string | string[];
  chip?: ChipLocalize;
  order?: number;
}

export interface ILocalizeSearchError {
  id: string;
  title: string;
  descripion: string;
  icon: string;
  color: string;
  action?: number;
}
export interface ILocalizeSearchResult {
  idErro: string | null;
  campos: ILocalizeSearchFields[];
}

export interface RequestResponse {
  requestId: string;
  parts: RequestResponsePart[];
  localizeSearchErrors: ILocalizeSearchError[];
  isLocalizeSearchAbled: boolean;
}

export interface RequestResponsePart {
  id: string;
  customerData: CustomerData;
  localizeSearchResult: ILocalizeSearchResult | null;
  attachedDocuments: FileModel[];
  attachedByUserDocuments: FileModel[];
  scores: Score[];
  digitalSignature: DigitalSignature;
  collectDocuments: CollectedDocuments[];
  generalScore: GeneralScore;
  messageStatus: string;
  status: string;
}

export interface DigitalSignature {
  dateTime: string;
  signatures: Signature[];
}

export interface GeneralScore {
  score: number;
  url?: string;
  appliedScore: number;
  configuredScore: number;
  approvalType: string;
  classification: ScoreClassification;
}

export interface Match {
  name: string;
  message: string;
  description: string;
  status: MatchStatus;
}

export interface Score {
  key: string;
  partId: string;
  src: string;
  type: string;
  title: ScoreSectionTitle;
  section: ScoreSectionType;
  matches: Match[];
}

export interface ExtractionData {
  confidence: number;
  fields: ExtractionDataField[];
}

export interface ExtractionDataField {
  confidence: string;
  label: string;
  name: string;
  value: string;
}
