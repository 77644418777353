import axios from 'axios';
import { notify } from '@utils/notification';
import HttpClient from '@infra/http-client';
import { AULoadingManager, StringUtils } from '@assertiva/assertiva-ui';
import { dispatchFetchConfigSms } from '@src/stores/registration';

const instance = axios.create();
const client = HttpClient('');

export const textToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const exportDocumentHandler = async (
  url,
  name,
  extension = 'pdf',
  withoutLoading
) => {
  try {
    if (!!!withoutLoading) AULoadingManager.show();

    const response = await instance({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });

    const src = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = src;
    const newName = name.split('.').length > 1 ? name : `${name}.${extension}`;
    link.setAttribute('download', `${newName}`);
    document.body.appendChild(link);
    link.click();
    AULoadingManager.close();
  } catch (error) {
    AULoadingManager.close();
  }
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const exportDocumentHandlerGet = async (url, parteId) => {
  const response = client.get({
    action: url,
    params: {
      parteId,
    },
    loading: true,
    config: {
      headers: {
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  });
  return response;
};

export const handleDownloadBlob = (blob, filename) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    notify('Download realizado com sucesso.');
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((y, f) => {
      return f(y);
    }, x);

export const mountWhatsAppMessage = ({ url, name, sender }) =>
  `${sender}: Olá ${
    name?.split(' ')?.[0]
  }! Clique no link e siga as instruções: ${url}. Válido por 120h.`;

export const handleOpenWhatsApp = async ({
  url,
  name,
  phone,
  preloadedSender,
  dispatch,
  callback,
}) => {
  try {
    let sender = preloadedSender;

    if (!sender) {
      AULoadingManager.show();
      sender = await dispatchFetchConfigSms(dispatch);
    }

    window.open(
      `https://wa.me/55${StringUtils.returnOnlyNumber(
        phone
      )}?text=${mountWhatsAppMessage({ url, name, sender })}`,
      '_blank'
    );

    callback?.(sender);
  } catch (err) {
    console.error(err);
  } finally {
    AULoadingManager.close();
  }
};
