// import HttpClient from '@infra/http-client';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import AutomaticApproval from '@models/stores/automatic-approval';
import { GlobalStore } from '@src/models/stores/global';
import HttpClient from '@infra/http-client';
import { notify } from '@src/utils/notification';

const clientJourney = HttpClient('');

const initialState: AutomaticApproval = {
  approvalValue: 85,
  isActive: false,
};

export const fetchAutomaticApproval = createAsyncThunk<AutomaticApproval>(
  'configuracao/aprovacao-automatica',
  async () => {
    const response = await clientJourney.get<{
      ativo: boolean;
      valor: number;
    }>({
      action: 'configuracoes/score-aprovacao-automatica',
      loading: true,
    });
    const result: AutomaticApproval = {
      approvalValue: response.data.ativo ? response.data.valor : 85,
      isActive: response.data.ativo,
    };
    return result;
  }
);

export const sendAutomaticApproval = createAsyncThunk<void, AutomaticApproval>(
  'configuracao/enviar-aprovacao-automatica',
  async (config: AutomaticApproval) => {
    await clientJourney.put({
      action: 'configuracoes/score-aprovacao-automatica',
      body: {
        ativo: config.isActive,
        valor: config.isActive ? config.approvalValue : 85,
      },
      loading: true,
    });
  }
);

export const automaticApprovalSlice = createSlice({
  name: 'automatic-approval',
  initialState: { ...initialState },
  reducers: {
    changeStatusDefault: (state) => {
      state.isActive = !state.isActive;
    },
    changeApprovalValue: (state, action: PayloadAction<number>) => {
      state.approvalValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAutomaticApproval.fulfilled, (state, { payload }) => {
      state.approvalValue = payload.approvalValue;
      state.isActive = payload.isActive;
    });
    builder.addCase(sendAutomaticApproval.fulfilled, (state, { payload }) => {
      notify('Regra de aprovação salva com sucesso.');
    });
  },
});

export const selectDefaulStatus = (state: GlobalStore) =>
  state.automaticApproval.isActive;

export const selectApprovalValue = (state: GlobalStore) =>
  state.automaticApproval.approvalValue;

export const { changeStatusDefault, changeApprovalValue } =
  automaticApprovalSlice.actions;

export default automaticApprovalSlice.reducer;
