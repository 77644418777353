import { DynamicField as Dynamic } from '@models/dynamic-field';
import { StringUtils } from '@assertiva/assertiva-ui';
import regex from '@src/constants/regex';
import Mask from '@src/models/mask';

const configMask = {
  cpf: (data: Dynamic) => {
    const { value } = data;
    return StringUtils.formatDocument(value as string, 'cpf');
  },
  cnpj: (data: Dynamic) => {
    const { value } = data;
    return StringUtils.formatDocument(value as string, 'cnpj');
  },
  celular: (data: Dynamic) => {
    const { value } = data;
    return StringUtils.formatPhone(value as string, 'mobile');
  },
  phone: (data: Dynamic | Mask) => {
    const { value } = data;
    return StringUtils.formatPhone(value as string, 'mobile');
  },
  document: (data: Dynamic | Mask) => {
    const { value } = data;
    const newValue = value?.replace(regex.specialCharactes, '');
    return newValue && newValue.length <= 11
      ? StringUtils.formatDocument(newValue as string, 'cpf')
      : StringUtils.formatDocument(newValue as string, 'cnpj');
  },
};

export default configMask;
