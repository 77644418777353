import { AuthUtils } from '@assertiva/assertiva-ui';
import { COMPANY_ID } from '@src/constants/company-id';
import { journeyPermissions } from '@src/constants/permissions';

export const hasJourneyAccess = (): boolean => {
  const permissions = AuthUtils.getUser().permissions;
  return permissions.some((per) => journeyPermissions.includes(per));
};

export const isAssertiva = () => {
  const companyId = COMPANY_ID[process.env.REACT_APP_APP_ENV ?? 'development'];
  const trialId = 7;

  const user = AuthUtils.getUser();

  return (
    user.company.id !== undefined &&
    [companyId, trialId].includes(user.company.id)
  );
};
