import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ButtonCustom } from '@src/components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface ValidateAnotherButtonProps {
  outlined?: boolean;
}

const ValidateAnotherButton: React.FC<ValidateAnotherButtonProps> = ({
  outlined,
}) => {
  const history = useHistory();

  const validateAnotherHandler = () => {
    history.push('/validar-hash');
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <ButtonCustom
        fullWidth
        id="validate-another-button"
        backgroundcolor="#8D23EC"
        onClick={validateAnotherHandler}
        variant={outlined ? 'outlined' : 'contained'}
        style={{
          color: 'white',
          borderColor: 'white',
          width: '100%',
        }}
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box width="100%">Validar outro</Box>
        </Box>
      </ButtonCustom>
    </Grid>
  );
};

export default ValidateAnotherButton;
