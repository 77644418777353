import _isEqual from 'lodash/isEqual';
import LoadingButton from '@src/components/LoadingButton';
import { AppDispatch } from '@src/stores';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
  AUBoxWrapper,
  AULoadingManager,
  AuthUtils,
  PRODUCT_IDS,
} from '@assertiva/assertiva-ui';
import { IFunctionality } from '@src/models/stores/functionalities/functionality';
import {
  dispatchFetchFunctionalities,
  putFunctionality,
} from '@src/stores/functionalities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { LOCALIZE_FUNCTIONALITY } from '@src/constants/functionality-tags';

const FunctionalitiesPage = () => {
  const [preloadedFunctionalities, setPreloadedFunctionalities] =
    useState<IFunctionality[]>();
  const [functionalities, setFunctionalities] = useState<IFunctionality[]>();

  const dispatch: AppDispatch = useDispatch();

  const onSubmit = async () => {
    try {
      if (!functionalities) return;
      await dispatch(putFunctionality(functionalities)).unwrap();
      setPreloadedFunctionalities(functionalities);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchFunctionalities = async () => {
      try {
        AULoadingManager.show();
        const resFunctionalities = await dispatchFetchFunctionalities(dispatch);
        setPreloadedFunctionalities(resFunctionalities);
        setFunctionalities(resFunctionalities);
      } catch (err) {
        console.error(err);
      } finally {
        AULoadingManager.close();
      }
    };

    fetchFunctionalities();
  }, [dispatch]);

  const handleChangeFunctionality = (fun: IFunctionality) => {
    setFunctionalities((prevValue) =>
      prevValue?.map((f) => (f.tag === fun.tag ? { ...f, ativo: !f.ativo } : f))
    );
  };

  const isFunctionalityDisabled = (fun: IFunctionality) => {
    if (fun.tag === LOCALIZE_FUNCTIONALITY) {
      return !AuthUtils.getUser().hasProductById(PRODUCT_IDS.LOCALIZE);
    }

    return false;
  };

  const submitDisabled = _isEqual(preloadedFunctionalities, functionalities);

  if (!functionalities?.length) return null;

  return (
    <AUBoxWrapper>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Paper style={{ width: '100%', maxWidth: 640 }}>
          <Box p={4} width="100%">
            <Box
              alignItems="center"
              flexDirection="column"
              display="flex"
              width="100%"
            >
              <Box pb={3} display="flex" flexDirection="column" width="100%">
                <Typography variant="h6">
                  Configuração de funcionalidades
                </Typography>
                <Box color="#666666">
                  <Typography variant="body2" color="inherit">
                    Gerencie as funcionalidades integradas ao Assertiva
                    Autentica.
                  </Typography>
                </Box>
                <Grid container spacing={3} style={{ marginTop: '4px' }}>
                  {functionalities.map((f) => (
                    <Grid key={f.tag} item xs={12}>
                      <Tooltip
                        arrow
                        title={
                          isFunctionalityDisabled(f)
                            ? 'Você não tem o produto Localize habilitado'
                            : ''
                        }
                      >
                        <div>
                          <FormControlLabel
                            checked={f.ativo}
                            onChange={() => handleChangeFunctionality(f)}
                            control={
                              <Switch
                                color="primary"
                                id={f.tag}
                                data-testid={`checkbox_${f.tag as string}`}
                              />
                            }
                            label={
                              <Box display="flex" alignItems="center">
                                <Typography variant="body2">
                                  {f.descricao}
                                </Typography>
                              </Box>
                            }
                            disabled={isFunctionalityDisabled(f)}
                          />
                        </div>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end">
              <LoadingButton
                onClick={onSubmit}
                style={{ color: 'white' }}
                variant="contained"
                color="primary"
                disabled={submitDisabled}
              >
                Salvar
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </AUBoxWrapper>
  );
};

export default FunctionalitiesPage;
