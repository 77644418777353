import styled from 'styled-components';

export const Container = styled.div`
  .appbar {
    box-shadow: 0px 3px 6px #00000033;

    .topbar {
      justify-content: space-between;
      flex-direction: row;

      .item {
        display: flex;
        align-items: center;
      }

      .logo-img {
        height: 36px;
        width: auto;
        display: flex;
        margin-top: -25px;
      }

      .user {
        display: flex;
        align-items: center;

        .info {
          margin: 0 10px;
        }
      }
    }
  }
`;

export const Content = styled.div`
  margin: auto;
  margin-top: 135px;
  min-height: 414px;
  min-width: 382px;
`;

export const WelcolmeTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #333a46;
`;
