import regex from '@src/constants/regex';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDefaultString = (string = '') => {
  const value = (string || '').toString().replace(regex.empty, '');
  return value;
};
