import styled from 'styled-components';

export const SignatureCard = styled.div<any>`
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 15px 15px;
  border-top: 16px solid
    ${(props) => (props.borderColor ? props.borderColor : 'transparent')};
`;

export const Region = styled.div`
  padding-top: 8px;
  width: 100%;
  border-bottom: 2px solid rgb(241, 241, 241);
  padding-bottom: 15px;
`;

export const BlockedTag = styled.div`
  background-color: rgb(245, 243, 243);
  border-radius: 3px;
  width: 170px;
  height: 15px;
  margin-top: 5px;
`;

export const FileNameText = styled.div`
  max-width: 160px;
  text-overflow: ellipsis;
`;
