import CustomNavBar from '@src/components/CustomNavBar';
import React from 'react';
import { Container } from '../styles';
import logo from 'assets/logo.svg';
import { Content, NotFoundText, NotFoundTitle } from './styles';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import ValidateAnotherButton from '../components/ValidateAnotherButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const NoHashFound = () => {
  return (
    <Container>
      <CustomNavBar logo={logo} position="absolute" />
      <Content>
        <SentimentDissatisfiedRoundedIcon
          color="primary"
          style={{ width: 250, height: 250 }}
        />
        <NotFoundTitle>Desculpe!</NotFoundTitle>
        <NotFoundText>
          Não encontramos nenhum documento relacionado ao código pesquisado.
        </NotFoundText>
        <Grid item xs={12}>
          <Box mt={7}>
            <ValidateAnotherButton />
          </Box>
        </Grid>
      </Content>
    </Container>
  );
};

export default NoHashFound;
