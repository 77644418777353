import StatusFilter from '@models/request/filter/status-type.model';
import { DynamicField } from './../../../models/dynamic-field';
import HttpClient from '@infra/http-client';
import ComboBox from '@models/combo-box';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import File from '@models/components/file';
import DynamicRequest from '@models/request/dynamic-request';
import KeyValue from '@models/key-value';
import { notify } from '@utils/notification';
import { ErrorHandled } from '@models/stores/error-handled';
const clientJourney = HttpClient('');

const initialState = {
  flows: [],
  profiles: [],
};

interface ComboResponse {
  id: string | number;
  nome: string;
}

interface FileResponse {
  id: string | number;
  nome: string;
  url: string;
  extensao: string;
}

interface DynamicFieldResponse {
  id: string | number;
  descricao: string;
  etiqueta: string;
  nome: string;
  tamanhoMaximo?: string;
  obrigatorio: boolean;
  tipo: any;
  validacao: string;
  valor: string;
}

interface FlowStatus {
  id: string;
  name: string;
}

const convertField = (fields: DynamicFieldResponse[]) => {
  const result = fields.map((field) => {
    const {
      id,
      descricao,
      etiqueta,
      nome,
      tamanhoMaximo,
      obrigatorio,
      tipo,
      validacao,
      valor,
    } = field;
    return {
      id,
      description: descricao,
      label: etiqueta,
      name: nome,
      maxLength: tamanhoMaximo,
      required: obrigatorio,
      type: tipo,
      mask: validacao,
      value: valor,
    };
  });
  return result as DynamicField[];
};

const convertFields = (fields: Partial<KeyValue[]>) => {
  const result = fields
    .map((field) => {
      if (field) {
        const { id, value } = field;
        return { id, valor: value };
      }
      return null;
    })
    .filter((e) => e !== null);
  return result;
};

const isValidResponse = (status) => [204, 200].includes(status);

export const fetchFlows = createAsyncThunk<ComboBox[]>(
  'jornada/pedido/fluxos/ativos',
  async () => {
    const index = 1;
    const size = 100;
    const result = await clientJourney.get<{ jornadas: ComboResponse[] }>({
      params: { index, size },
      loading: false,
      action: 'fluxos/ativos',
    });

    if (result) {
      const requests = result.data.jornadas.map((request) => {
        return {
          id: request.id,
          name: request.nome,
        };
      }) as ComboBox[];

      return requests;
    }
    return [];
  }
);

export const fetchFiles = createAsyncThunk<File[], string>(
  'jornada/pedido/anexos',
  async (id) => {
    const response = await clientJourney.get<{ anexos: FileResponse[] }>({
      action: 'fluxos/anexos',
      params: { fluxoId: id },
    });

    if (response) {
      const files = response.data.anexos;
      const defaultFiles = files?.map((e) => {
        return {
          id: e.id,
          name: e.nome,
          url: e.url,
          extension: e.extensao,
          isDefault: true,
        };
      }) as File[];
      return defaultFiles;
    }

    return [];
  }
);

export const fetchProfiles = createAsyncThunk<ComboBox[]>(
  'jornada/pedido/perfis',
  async () => {
    const result = await clientJourney.get<{ perfis: ComboResponse[] }>({
      action: 'perfis-assinatura',
      loading: false,
    });

    if (result && result.data) {
      const profiles = result.data.perfis;
      const converterProfiles = profiles.map((profile) => {
        const { id, nome } = profile;
        const result = { id, name: nome };
        return result;
      }) as ComboBox[];
      return converterProfiles;
    }
    return [];
  }
);

export const fetchDynamicFields = createAsyncThunk<
  DynamicField[],
  string | number
>('jornada/pedido/campos-dinamicos', async (id) => {
  const result = await clientJourney.get<{
    perfil: { campos: DynamicFieldResponse[] };
  }>({
    action: 'listas-envio/obter-perfil-lista-envio',
    params: { listaEnvioId: id },
    loading: false,
  });

  if (result && result.data) {
    const fields = result.data.perfil.campos;
    const converterProfiles = convertField(fields);
    return converterProfiles;
  }
  return [];
});

export const createDynamicRequest = createAsyncThunk<object, DynamicRequest>(
  'jornada/pedido/criar-pedido-dinamico',
  async (dynamicRequest) => {
    const { id, fields } = dynamicRequest;
    const result = await clientJourney.post({
      action: 'listas-envio/adicionar-pedido-lista-envio',
      loading: true,
      body: { listaEnvioId: id, campos: convertFields(fields) },
    });
    return result;
  }
);

export const publishFlow = createAsyncThunk<
  void,
  string,
  { rejectValue: ErrorHandled }
>('jornada/fluxo/publicar', async (id, { dispatch, rejectWithValue }) => {
  const result = await clientJourney.patch({
    action: 'fluxos/publicar',
    loading: true,
    body: { fluxoId: id },
  });
  if (!result || !isValidResponse(result?.status)) {
    return rejectWithValue({
      errorMessage: 'Ocorreu um erro ao publicar o fluxo',
    });
  }
});

export const unpublishFlow = createAsyncThunk<
  void,
  string,
  { rejectValue: ErrorHandled }
>('jornada/fluxo/despublicar', async (id, { dispatch, rejectWithValue }) => {
  const result = await clientJourney.patch({
    action: 'fluxos/despublicar',
    loading: true,
    body: { fluxoId: id },
  });
  if (!result || !isValidResponse(result?.status)) {
    return rejectWithValue({
      errorMessage: 'Ocorreu um erro ao despublicar o fluxo',
    });
  }
});

export const discontinueFlow = createAsyncThunk<
  void,
  string,
  { rejectValue: ErrorHandled }
>('jornada/fluxo/arquivar', async (id, { dispatch, rejectWithValue }) => {
  const result = await clientJourney.patch({
    action: 'fluxos/arquivar',
    loading: true,
    body: { fluxoId: id },
  });

  if (!result || !isValidResponse(result?.status)) {
    return rejectWithValue({
      errorMessage: 'Ocorreu um erro ao arquivar o fluxo',
    });
  }
});

export const restaureFlow = createAsyncThunk<
  void,
  FlowStatus,
  { rejectValue: ErrorHandled }
>(
  'jornada/fluxo/desarquivar',
  async ({ id, name }, { dispatch, rejectWithValue }) => {
    const result = await clientJourney.patch({
      action: 'fluxos/desarquivar',
      loading: true,
      body: { fluxoId: id, novoNome: name },
    });
    if (!result || !isValidResponse(result?.status)) {
      return rejectWithValue({
        errorMessage: 'Ocorreu um erro ao desarquivar o fluxo',
      });
    }
  }
);

export const duplicateFlow = createAsyncThunk<
  void,
  FlowStatus,
  { rejectValue: ErrorHandled }
>(
  'jornada/fluxo/duplicar',
  async ({ id, name }, { dispatch, rejectWithValue }) => {
    const result = await clientJourney.post({
      action: 'fluxos/duplicar',
      loading: true,
      body: { fluxoId: id, nome: name },
    });
    if (result.status !== 200) {
      return rejectWithValue({
        errorMessage: 'Ocorreu um erro ao duplicar o fluxo',
      });
    }
  }
);

export const sendMultipleRequest = createAsyncThunk<
  object,
  { url: string; file: string | ArrayBuffer }
>('jornada/pedido/multiplos-pedidos', async (multipleRequest) => {
  const { url, file } = multipleRequest;
  await fetch(url, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': '',
    },
  });
  return {};
});

export const fetchStatus = createAsyncThunk<StatusFilter[], string>(
  'jornada/status',
  async (type) => {
    const response = await clientJourney.get<{
      listagem: { id: string; value: any }[];
    }>({
      action: `partes/listar-grupo-status`,
      params: { grupo: type },
    });
    if (response && response.status === 200) {
      const result = response.data.listagem.map((status) => ({
        ...status,
        checked: false,
      }));
      return result as StatusFilter[];
    }
    return [];
  }
);
export const fetchResendStatus = createAsyncThunk<StatusFilter[], string>(
  'jornada/status/reenviar',
  async (id) => {
    const responseResend = await clientJourney.get<{
      contadores: { statusId: string; status: string }[];
    }>({
      action: `partes/contador-grupo-status`,
      params: { grupo: 'fechados', listaEnvioId: id },
      loading: false,
    });

    if (responseResend.status === 200) {
      const resultResendStatus = responseResend.data.contadores.map<string>(
        (group) => group.statusId
      );

      const response = await clientJourney.get<{
        listagem: { id: string; value: any }[];
      }>({
        action: `partes/listar-grupo-status`,
        params: { grupo: 'reenviar' },
      });

      if (response && response.status === 200) {
        const result = response.data.listagem.map<StatusFilter>((status) => {
          if (resultResendStatus.includes(status.id)) {
            return {
              ...status,
              checked: false,
              disabled: false,
            };
          } else {
            return {
              ...status,
              checked: false,
              disabled: true,
              textDisabled: `Não existem solicitações a serem reenviadas pelo status ${status.value}`,
            };
          }
        });
        const existingValidValue = result.filter((e) => !e.disabled);
        return existingValidValue.length > 0 ? result : [];
      }

      return [];
    }
    return [];
  }
);

export const newRequestSlice = createSlice({
  name: 'request',
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createDynamicRequest.fulfilled, () => {
      notify('Destinatário adicionado com sucesso.');
    });
    builder.addCase(unpublishFlow.fulfilled, () => {
      notify('O fluxo foi despublicado com sucesso!');
    });
    builder.addCase(publishFlow.fulfilled, () => {
      notify('O fluxo foi publicado com sucesso!');
    });
    builder.addCase(restaureFlow.fulfilled, () => {
      notify('O fluxo foi restaurado com sucesso!');
    });
    builder.addCase(discontinueFlow.fulfilled, () => {
      notify('O fluxo foi arquivado com sucesso!');
    });
    builder.addCase(duplicateFlow.fulfilled, () => {
      notify('O fluxo foi duplicado com sucesso!');
    });
  },
});

export default newRequestSlice.reducer;
