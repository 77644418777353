import TermsModel from '@pages/Builder/models/terms.model';
import ArtifactItemModel from '@src/pages/Builder/models/artifact-item.model';
import { IFunctionalityResponse } from './interfaces/functionality-response';
import AnnexModel from '@pages/Builder/models/annex.model';
import ProposalModel from '@pages/Builder/models/proposal.model';
import { QuestionAnswer } from '@pages/Builder/models/question.model';
import CaptureModel from '@pages/Builder/models/capture.model';
import QuestionModel from '@pages/Builder/models/question.model';
import InstructionsModel from '@pages/Builder/models/intructions.model';
import { ArtifactItemEnum, ArtifacType } from './../../utils/enum/artifact';
import PresentationsModel from '@pages/Builder/models/presentations.model';
import { ArtifactEnum } from '@src/utils/enum/artifact';
import BasicArtifactModel from '@pages/Builder/models/basic-artifact.model';
import Collect, { TypeCollect } from '@pages/Builder/models/collect.model';
import { IFunctionality } from '@src/models/stores/builder/functionalities';
import { removeEmpty } from '@src/utils/array-utils';
import { IFlowFunctionality } from './interfaces/flow';

export const convertFunctionalities = (
  functionalities: IFunctionalityResponse[]
): IFunctionality[] => {
  const result = functionalities.map<IFunctionality>((functionality) => {
    const {
      nome: name,
      label,
      tipo: type,
      pago: paid,
      artefatosPermitidos: allowedArtifacts,
    } = functionality;
    return { name, label, type, paid, allowedArtifacts, able: true };
  });
  return result;
};

const convertFunctionalitiesRequest = (
  functionalities: IFunctionality[],
  bindedArtifact: string | null = null
) => {
  const result = functionalities.map((functionality) => {
    const {
      label,
      name: nome,
      type: tipo,
      paid: pago,
      able: habilitado,
    } = functionality;
    return {
      tipo,
      itens: [
        {
          nome,
          pago,
          tipo,
          habilitado,
          label,
          artefatoVinculado: bindedArtifact ? { nome: bindedArtifact } : null,
        },
      ],
    };
  });
  return result;
};

const convertBasicArtifact = (
  basicArtifact: BasicArtifactModel,
  hasDescription?: boolean
) => {
  const { file, title, body, button } = basicArtifact;
  if (file?.base64) {
    file.base64 = file.base64.toString().replaceAll('data:', '');
  }
  const propriedades = removeEmpty({
    imagem: file ? `data:${file?.base64}` : null,
    titulo: title,
    ...(hasDescription ? { subtitulo: body } : { descricao: body }),
    textoBotao: button,
  });
  return propriedades;
};

const convertProposalArtifact = (basicArtifact: BasicArtifactModel) => {
  const { file, title, body, button } = basicArtifact;
  if (file?.base64) {
    file.base64 = file.base64.toString().replaceAll('data:', '');
  }
  const propriedades = removeEmpty({
    imagem: file ? `data:${file?.base64}` : null,
    titulo: title,
    textoConfirmacao: body,
    textoBotao: button,
  });
  return propriedades;
};

const convertPreparingArtifact = (basicArtifact: BasicArtifactModel) => {
  const { file, title, body, button } = basicArtifact;
  if (file?.base64) {
    file.base64 = file.base64.toString().replaceAll('data:', '');
  }
  const propriedades = removeEmpty({
    imagem: file ? `data:${file?.base64}` : null,
    titulo: title,
    textoConteudo: body,
    textoBotao: button,
  });
  return propriedades;
};

const getArtifactAndSubartifact = (artifacts: ArtifactItemModel[]) => {
  const artifact: any = artifacts.find((artifact) => !artifact.fatherId);
  const subArtifacts: any = artifacts.filter((artifact) => !!artifact.fatherId);
  return { artifact, subArtifacts };
};

const setSubartifactObject = (
  artifact: ArtifactItemModel | undefined,
  subArtifacts: ArtifactItemModel[],
  position: number
) => {
  let result: any = {
    nome: artifact?.name,
    posicao: position + 1,
    tipo: ArtifacType.Collector,
  };

  const artefatos = subArtifacts.map((subArtifact) => {
    const meth = convertSubArtifacts[subArtifact.name];
    if (meth) return meth(subArtifact.value);
    return {};
  });

  artefatos.forEach((artefato) => {
    result = { ...result, ...artefato };
  });

  return result;
};

const collection = (collections: Collect[]) => {
  const result: TypeCollect[] = collections.reduce((arr, el) => {
    const element = el.types.map((elCollect, index) => {
      return {
        ...elCollect,
        index: index + 1,
      };
    });
    arr = arr.concat(element as any);
    return arr;
  }, []);
  return result;
};

const collectionWithQuestion = (answers: QuestionAnswer[]) => {
  const result: TypeCollect[] = answers.reduce((arr, el) => {
    let index = 0;
    const element = el.collectionsType
      .map((elCollect) => {
        return elCollect.types.map((type) => {
          index++;
          return {
            ...type,
            indexAnswer: el.indexAnswer,
            index,
          };
        });
      })
      .reduce((x, y) => {
        return x.concat(y);
      });
    arr = arr.concat(element as any);
    return arr;
  }, []);

  return result;
};

const capture = (artifacts: ArtifactItemModel[], position: number) => {
  const { artifact: capture, subArtifacts } =
    getArtifactAndSubartifact(artifacts);
  const value: CaptureModel = capture?.value;
  let result = setSubartifactObject(capture, subArtifacts, position);

  result = { ...result, tipoComprovacao: value?.type };

  if (capture.name === ArtifactItemEnum.CaptureFace) {
    const collections = collection(value.collectionsType);

    const coletas = collections.map((collect) => {
      const { title: titulo, type: tipo, index: indice } = collect;

      return { titulo, tipo, indice };
    });
    return { ...result, coletas };
  }

  return { ...result };
};

const convertSubArtifacts = {
  [ArtifactEnum.Preparing]: (preparing: BasicArtifactModel) => {
    const basic = convertBasicArtifact(preparing);
    return { preparacao: basic };
  },
  [ArtifactEnum.Instructions]: (instructions: InstructionsModel) => {
    const { instructions: instructionsItems } = instructions;
    const result = instructionsItems.map((instruction, index) => {
      const {
        title: titulo,
        file,
        body: texto,
        button: textoBotao,
      } = instruction;
      if (file?.base64) {
        file.base64 = file.base64.toString().replaceAll('data:', '');
      }
      return {
        texto,
        imagem: file ? `data:${file?.base64}` : null,
        titulo,
        textoBotao,
        indice: index + 1,
      };
    });
    return { instrucoes: result };
  },
  [ArtifactEnum.Question]: (questions: QuestionModel) => {
    const { title, answers } = questions;

    const respostas = answers.map((answer) => {
      const titles = answer.collectionsType
        .map<string>((collect) => collect.description)
        .join(' + ');
      const { indexAnswer: indiceResposta } = answer;
      return { texto: titles, indiceResposta };
    });

    const collect = collectionWithQuestion(answers);

    const coletas = collect.map((collect) => {
      const {
        index: indice,
        indexAnswer: indiceResposta,
        title: titulo,
        type: tipo,
      } = collect;

      return { indice, indiceResposta, titulo, tipo };
    });

    const questao = { texto: title, respostas };
    return { questao, coletas };
  },
};

export const convertJson = {
  [ArtifactEnum.SlidePresentation]: (
    presentations: PresentationsModel,
    position: number
  ) => {
    const { slides: slidesPresentation } = presentations;

    if (slidesPresentation.length > 1) {
      const slides = slidesPresentation.map((slide, index) => {
        const propriedades = {
          propriedades: { ...convertBasicArtifact(slide, true) },
          indice: index + 1,
        };

        return propriedades;
      });

      const result = removeEmpty({
        nome: ArtifactEnum.SlidePresentation,
        posicao: position + 1,
        tipo: ArtifacType.Info,
        slides,
      });
      return result;
    }
    const propriedades = convertBasicArtifact(slidesPresentation[0], true);
    const result = {
      nome: ArtifactEnum.Presentation,
      posicao: position + 1,
      tipo: ArtifacType.Info,
      propriedades,
    };
    return result;
  },
  [ArtifactEnum.Terms]: (terms: TermsModel, position: number) => {
    const {
      button: textoBotao,
      confirm: textoConfirmacao,
      title: textoCabecalho,
    } = terms;
    const result = removeEmpty({
      nome: ArtifactEnum.Terms,
      posicao: position + 1,
      tipo: ArtifacType.Info,
      propriedades: { textoBotao, textoConfirmacao, textoCabecalho },
    });
    return result;
  },
  [ArtifactEnum.CaptureProof]: (
    artifacts: ArtifactItemModel[],
    position: number
  ) => {
    return capture(artifacts, position);
  },
  [ArtifactEnum.CaptureFace]: (
    artifacts: ArtifactItemModel[],
    position: number
  ) => {
    return capture(artifacts, position);
  },
  [ArtifactEnum.CaptureDocument]: (
    artifacts: ArtifactItemModel[],
    position: number
  ) => {
    return capture(artifacts, position);
  },
  [ArtifactEnum.Preparing]: (prepare: BasicArtifactModel, position: number) => {
    const { title, body, button, file } = prepare;
    const propriedades = convertPreparingArtifact({
      title,
      body,
      button,
      file,
    });
    const result = {
      nome: ArtifactEnum.Preparing,
      posicao: position + 1,
      tipo: ArtifacType.Info,
      propriedades,
    };
    return result;
  },
  [ArtifactEnum.Instructions]: (
    instruction: InstructionsModel,
    position: number
  ) => {
    const method = convertSubArtifacts[ArtifactEnum.Instructions];
    const { instrucoes: itens } = method(instruction);
    const result = {
      nome: ArtifactEnum.Instructions,
      posicao: position + 1,
      tipo: ArtifacType.Info,
      itens,
    };
    return result;
  },
  [ArtifactEnum.Annex]: (annex: AnnexModel, position: number) => {
    const { title, body, button, annexs } = annex;
    const propriedades = convertBasicArtifact({ title, body, button }, true);
    const arquivosNecessarios = annexs.map((annex, key) => {
      const { title, required } = annex;
      return { nomeArquivo: title, indice: key + 1, obrigatorio: required };
    });
    const result = {
      nome: ArtifactEnum.Annex,
      posicao: position + 1,
      tipo: ArtifacType.Collector,
      propriedades,
      arquivosNecessarios,
    };
    return result;
  },
  [ArtifactEnum.Proposal]: (proposal: ProposalModel, position: number) => {
    const value: ProposalModel = proposal;
    const { title, body, button } = value;
    const propriedades = convertProposalArtifact({ title, body, button });
    const result = {
      nome: ArtifactEnum.Proposal,
      posicao: position + 1,
      tipo: ArtifacType.Collector,
      propriedades,
      documentos: [],
    };
    return result;
  },
  [ArtifactEnum.Feedback]: (feedback: BasicArtifactModel, position: number) => {
    const propriedades = convertBasicArtifact(feedback, true);
    const result = {
      nome: ArtifactEnum.Feedback,
      posicao: position + 1,
      tipo: ArtifacType.Conclusion,
      propriedades,
    };
    return result;
  },
};

export const FlowConvertor = () => {
  let name: string;
  let color: string;
  let artifacts: ArtifactItemModel[];
  let functionalities: IFlowFunctionality;

  const convertColor = () => {
    return {
      corDestaque: color,
      corPrimaria: color,
    };
  };

  const convertAnnex = () => {
    const artifact = artifacts.find(
      (artifact) => artifact.name === ArtifactItemEnum.Proposal
    );

    if (artifact) {
      const proposal: ProposalModel = artifact.value;
      const { files } = proposal;
      if (files) {
        const documentos = files.map((file) => {
          const { name: nome, extension: extensao, base64 } = file;
          return { nome, extensao, base64 };
        });
        return documentos;
      }
    }
    return [];
  };

  const convertFunctionalities = () => {
    const { all, allowedArtifacts } = functionalities;
    if (all && allowedArtifacts) {
      const { artifact: artifactFunctions, flow, mobile } = all;
      const allowed = artifacts.filter((artifact) =>
        allowedArtifacts.includes(artifact.name)
      );

      const flowRequest = convertFunctionalitiesRequest(flow);
      const mobileRequest = convertFunctionalitiesRequest(mobile);
      if (allowed.length > 0) {
        const result = allowed.reduce((arr: any[], artifact) => {
          const functionalities: IFunctionality[] | undefined =
            artifact.value.functionalities;
          const functionality = convertFunctionalitiesRequest(
            functionalities && functionalities.length > 0
              ? functionalities
              : artifactFunctions.filter((x) =>
                  x.allowedArtifacts?.includes(artifact.name)
                ),
            artifact.name.toString()
          );
          return [...arr, ...functionality];
        }, []);
        return [...flowRequest, ...mobileRequest, ...result];
      }
      return [...flowRequest, ...mobileRequest];
    }
  };

  const convertArtifact = () => {
    const artefatos = artifacts
      .filter((artifact) => !artifact.fatherId)
      .map((artifact, index) => {
        const convertion = convertJson[artifact.name];

        if (convertion) {
          if (
            [
              ArtifactItemEnum.CaptureDocument,
              ArtifactItemEnum.CaptureFace,
              ArtifactItemEnum.CaptureProof,
            ].includes(artifact.name)
          ) {
            const artifactsWithSubArtifact = artifacts?.filter(
              (e) => e.id === artifact.id || e.fatherId === artifact.id
            );

            return convertion(artifactsWithSubArtifact, index);
          }
          const result = convertion(artifact.value, index);

          return result;
        }
        return {};
      });
    return artefatos;
  };
  return Object.assign(
    {},
    {
      forArtifacts: function (flowArtifact: ArtifactItemModel[]) {
        artifacts = flowArtifact;
        return this;
      },

      withName: function (flowName: string) {
        name = flowName;
        return this;
      },

      withColor: function (flowColor: string) {
        color = flowColor;
        return this;
      },
      withFunctionalities: function (flowFunctionalities: IFlowFunctionality) {
        functionalities = flowFunctionalities;
        return this;
      },

      build: () => {
        const artefatos = convertArtifact();
        const tema = convertColor();
        const funcionalidades = convertFunctionalities();
        const anexos = convertAnnex();
        const result = {
          nome: name,
          ativa: true,
          anexos,
          funcionalidades,
          composicao: { tema, artefatos },
        };
        return result;
      },
    }
  );
};
