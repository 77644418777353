import React, { useEffect, useState } from 'react';
import { Container, Content, PageContainer } from './styles';

import {
  AUNavBar,
  AUNotificationHub,
  AUSideBar,
  AUValidateAccessProduct,
  PRODUCT_IDS,
  SIDEBAR_WIDTH,
  useSidebar,
} from '@assertiva/assertiva-ui';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Routes from '@routes';
import S3_URL from '@src/constants/images';
import { changeMenu } from '@stores/layout';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset } from 'stores/authentication-slice';

export default function Office({ children, width, path }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openSidebar, setOpenSidebar] = useState(!isMobile);
  // const openSidebar = useSelector(selectMenuIsOpen);

  const handleSidebar = () => {
    setOpenSidebar(!openSidebar);
    //TODO: Por algum motivo não é executado, verificar depois no assertiva-ui
    dispatch(changeMenu(!openSidebar));
  };

  const history = useHistory();

  useEffect(() => {
    if (path?.includes('construtor')) {
      setOpenSidebar(false);
      dispatch(changeMenu(false));
    }
  }, [path, dispatch]);

  const { FEATURE_FLAG_SIDEBAR_NOVA_VERSAO } = useSidebar();

  useEffect(() => {
    window.root.style.setProperty(
      '--sidebarWidthOpen',
      FEATURE_FLAG_SIDEBAR_NOVA_VERSAO ? SIDEBAR_WIDTH.OPEN : '225px'
    );
    window.root.style.setProperty(
      '--sidebarWidthClosed',
      FEATURE_FLAG_SIDEBAR_NOVA_VERSAO ? SIDEBAR_WIDTH.CLOSED : '54px'
    );
  }, [FEATURE_FLAG_SIDEBAR_NOVA_VERSAO]);

  return (
    <Container>
      <AUNavBar
        handleClickIconButton={handleSidebar}
        logo={S3_URL.LOGO}
        handleAfterLogout={() => {
          dispatch(reset());
          localStorage.clear();
          history.push('/');
        }}
        showEditUserProfileOption={false}
        onClickEditUserProfileOption={() => history.push('/editar/perfil')}
        handleClickLogoIcon={() => history.push('/inicio')}
      />
      <AUSideBar
        routes={[...Routes()]}
        path={path}
        open={openSidebar}
        onClose={handleSidebar}
        onOpen={handleSidebar}
        variant={isMobile ? 'temporary' : 'permanent'}
        width={width}
      />
      <Content expanded={!openSidebar}>
        <PageContainer>
          <AUValidateAccessProduct productId={PRODUCT_IDS.AUTENTICA}>
            <Box maxWidth={1118} mx="auto">
              <AUNotificationHub productId={PRODUCT_IDS.AUTENTICA} />
            </Box>
            {children}
          </AUValidateAccessProduct>
        </PageContainer>
      </Content>
    </Container>
  );
}
