import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { styled } from '@material-ui/core/styles';
import { IBasicStyle } from '@src/models/basic-style';

interface Props extends IBasicStyle {
  width?: number;
  height?: number;
  fill: string;
}

const CustomCheckCircleIcon = styled(CheckCircleIcon)(
  ({ height, width, fill }: Props) => {
    return {
      width: width ?? '20px',
      height: height ?? '20px',
      '&&& path': {
        fill,
      },
    };
  }
);

export default CustomCheckCircleIcon;
