import { ButtonCustom } from '@src/components/Button';
import CustomNavBar from '@src/components/CustomNavBar';
import { getSignatures } from '@src/stores/hashValidator/actions';
import { errorMessages } from '@src/utils/DynamicFields/fields';
import logo from 'assets/logo.svg';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import HashValidatorFormModel from './models/hash-validator-form.model';
import { WelcolmeTitle } from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const HashValidator = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const { control, handleSubmit, errors } = useForm<HashValidatorFormModel>();
  const { REACT_APP_RECAPTCHA_KEY } = process.env;
  const recaptchaRef: any = useRef({});

  const onSubmit: SubmitHandler<HashValidatorFormModel> = async (form) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    dispatch(
      getSignatures({
        history,
        authenticatorCode: form.authenticatorCode,
        checkerCode: form.checkerCode,
        recaptchaResponse: recaptchaValue,
      })
    );
  };

  const handleRecaptcha = (status: boolean) => {
    setRecaptchaSuccess(status);
  };

  useEffect(() => {
    const queryAuthenticatorCode = query
      .get('codigoAutenticador')
      ?.replaceAll(' ', '+');

    const queryCheckerCode = query.get('codigoVerificador');

    if (queryAuthenticatorCode)
      control.setValue('authenticatorCode', queryAuthenticatorCode);

    if (queryCheckerCode) control.setValue('checkerCode', queryCheckerCode);
  }, [control, query]);

  return (
    <Container>
      <CustomNavBar logo={logo} position="absolute" />
      <Grid
        item
        xs={12}
        style={{
          margin: 'auto',
          marginBottom: 38,
          maxWidth: 546,
          marginTop: 135,
          textAlign: 'center',
        }}
      >
        <WelcolmeTitle>
          Bem vindo ao validador de documentos do Assertiva Autentica
        </WelcolmeTitle>
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        style={{ margin: 'auto' }}
      >
        <Grid item xs={12}>
          <Paper>
            <Box style={{ padding: '44px 34px 29px 34px' }}>
              <Grid item xs={12}>
                <Controller
                  name="authenticatorCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: errorMessages.required('Cód autenticador'),
                    },
                  }}
                  render={({ onChange, value }) => (
                    <Box mb={3}>
                      <TextField
                        id="authenticator-code"
                        fullWidth
                        size="small"
                        label="Cód autenticador"
                        value={value}
                        variant="outlined"
                        error={errors?.authenticatorCode !== undefined}
                        helperText={errors?.authenticatorCode?.message}
                        onChange={onChange}
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="checkerCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: errorMessages.required('Cód verificador'),
                    },
                  }}
                  render={({ onChange, value }) => (
                    <Box mb={3}>
                      <TextField
                        fullWidth
                        id="checker-code"
                        size="small"
                        label="Cód verificador"
                        value={value}
                        variant="outlined"
                        error={errors?.checkerCode !== undefined}
                        helperText={errors?.checkerCode?.message}
                        onChange={onChange}
                      ></TextField>
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={REACT_APP_RECAPTCHA_KEY}
                  onChange={() => handleRecaptcha(true)}
                  onExpired={() => handleRecaptcha(false)}
                  onErrored={() => handleRecaptcha(false)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <ButtonCustom
                    id="validate-button"
                    disabled={!recaptchaSuccess}
                    fullWidth
                    backgroundcolor="#8D23EC"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box width="100%">Validar</Box>
                    </Box>
                  </ButtonCustom>
                </Box>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HashValidator;
