import React from 'react';
import ReactDOM from 'react-dom';
import PrivateRoute from './privateRouteConfig';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { materialTheme } from './theme/default';
import { GlobalStyle } from './styles';
import { AUProvider } from '@assertiva/assertiva-ui';
import store from './stores';

ReactDOM.render(
  <AUProvider
    theme={materialTheme as any}
    snackbarProviderProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      maxSnack: 3,
      resumeHideDuration: 3000,
      preventDuplicate: true,
    }}
    featureFlagProvider
  >
    <Provider store={store}>
      <GlobalStyle />
      <PrivateRoute />
    </Provider>
  </AUProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
