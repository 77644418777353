import StatusFilter from '@src/models/request/filter/status-type.model';
import { EditRequestGroup } from '@src/models/request/group/edit-request-group';
import { DeparaStatus } from '@utils/enum/depara-status';
import Status from '@models/components/chip-status-count';
import HttpClient from '@infra/http-client';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import RequestGroup from '@src/models/stores/request/group/group';
import CreateRequestGroup from '@src/models/request/group/create-request-group';
import { notify } from '@utils/notification';
import { GlobalStore } from '@models/stores/global';
import RequestGroupGrid from '@src/models/request/group/request-group-table';
import { GroupTablePagination } from '@models/stores/request/group/pagination';
import ReceiverTable from '@src/models/request/group/receiver-table';
import ComboBoxModel from '@src/models/combo-box';
import EditFile from '@src/models/components/edit-file';
import EditRequestList from '@src/models/stores/request/group/edit';
import { AxiosResponse } from 'axios';
const clientJourney = HttpClient('');

const initialState: RequestGroup = {
  pagination: { rowsPerPage: 10, page: 0, count: 0 },
  paginationReceiver: { rowsPerPage: 10, page: 0, count: 0 },
  isTableView: true,
};

interface ChipStatusCountResponse {
  status: string;
  total: number;
}

interface RequestGroupTabledResponse {
  id: string;
  status: string;
  nome: string;
  quantidade: string;
  dataCriacao: string;
  proprietario: string;
  arquivoModeloUrl: string;
  uploadUrl: string;
}

interface RequestSentTabledResponse {
  id: string;
  dataHora: string;
  documento: string;
  celular: string;
}

interface RequestQuantity {
  id: string;
  status: StatusFilter[];
}

interface ReceiverTableResponse {
  total: number;
  pages: number;
  arquivoErroUrl: string;
  arquivoStatus: string;
  pedidos: RequestSentTabledResponse[];
}

const convertFiles = (data) => {
  return data.map((file) => {
    const { id, nome, url } = file;
    const splitNome = nome.split('.');
    return { id, name: splitNome[0], url };
  });
};

const fetchRequests = async (pagination: {
  page: number;
  rowsPerPage: number;
  name?: string;
}) => {
  const response = await clientJourney.get<{
    total: number;
    pages: number;
    listas: RequestGroupTabledResponse[];
  }>({
    action: 'listas-envio/obter-listas-envio',
    params: {
      index: pagination.page === 0 ? 1 : pagination.page,
      size: pagination.rowsPerPage,
      nome: pagination.name ? pagination.name : '',
    },
  });

  if (response && response.data) {
    const groupGrid = response.data.listas.map<RequestGroupGrid>((group) => {
      const {
        id,
        status,
        nome,
        quantidade,
        dataCriacao,
        proprietario,
        arquivoModeloUrl,
        uploadUrl,
      } = group;
      const list: RequestGroupGrid = {
        id,
        status,
        name: nome,
        quantity: quantidade,
        dataStart: dataCriacao,
        owner: proprietario,
        templateFileUrl: arquivoModeloUrl,
        uploadUrl,
      };
      return list;
    });

    const result: GroupTablePagination<RequestGroupGrid> = {
      groupGrid,
      rowsPerPage: pagination.rowsPerPage,
      count: response.data.total,
      page: pagination.page,
    };

    return result;
  }

  const result: GroupTablePagination<RequestGroupGrid> = {
    page: 0,
    count: 10,
    rowsPerPage: 10,
    groupGrid: [],
  };
  return result;
};

export const fetchReceiver = createAsyncThunk<
  GroupTablePagination<ReceiverTable>,
  { page?: number; rowsPerPage?: number; id?: string }
>('jornada/pedido/grupo/lista/destinatario', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState() as GlobalStore;
  const response = await clientJourney
    .get<ReceiverTableResponse>({
      action: 'listas-envio/obter-pedidos-lista-envio',
      params: {
        index: pagination.page === 0 ? 1 : pagination.page,
        size: pagination.rowsPerPage
          ? pagination.rowsPerPage
          : state.requestGroup.paginationReceiver.rowsPerPage,
        id: pagination.id ? pagination.id : '',
      },
    })
    .catch((e) => {
      const error = e.response.data;
      error.data.pedidos = [];
      return error as AxiosResponse<ReceiverTableResponse>;
    });

  if (response && response.data) {
    const groupGrid = response.data.pedidos.map<ReceiverTable>((group) => {
      const { id, dataHora, documento, celular } = group;
      const list: ReceiverTable = {
        id,
        data: dataHora,
        document: documento,
        phone: celular,
        detail: 'Visualizar',
      };
      return list;
    });
    const result: GroupTablePagination<ReceiverTable> = {
      groupGrid,
      errorUrl: response.data.arquivoErroUrl,
      statusFile: response.data.arquivoStatus,
      rowsPerPage: pagination.rowsPerPage
        ? pagination.rowsPerPage
        : state.requestGroup.paginationReceiver.rowsPerPage,
      count: response.data.total,
      page: pagination.page
        ? pagination.page
        : state.requestGroup.paginationReceiver.page,
    };

    return result;
  }

  const result: GroupTablePagination<ReceiverTable> = {
    page: 0,
    count: 10,
    rowsPerPage: 10,
    groupGrid: [],
  };
  return result;
});

export const createList = createAsyncThunk<object, CreateRequestGroup>(
  'jornada/pedido/grupo/criar',
  async (part: CreateRequestGroup) => {
    const { flow: fluxoId, name: nome, files, profile: perfilId } = part;
    const anexos = files
      .filter((file) => !file.isDefault)
      .map((file) => {
        return {
          artefato: file.artefact,
          nome: file.name,
          chave: file.key,
          extensao: file.extension,
        };
      });

    const anexosFluxo = files
      .filter((file) => file.isDefault)
      .map((file) => {
        return file.id;
      });
    const result = { fluxoId, nome, perfilId, anexos, anexosFluxo };

    await clientJourney.post({
      action: 'listas-envio',
      loading: true,
      body: result,
    });

    return {};
  }
);

export const editList = createAsyncThunk<object, EditRequestGroup>(
  'jornada/pedido/grupo/editar',
  async (part: EditRequestGroup) => {
    const { flow: fluxoId, name: nome, id } = part;

    const result = {
      fluxoId,
      nome,
      anexos: null,
      anexosFluxo: null,
      id,
    };

    await clientJourney.put({
      action: 'listas-envio',
      loading: true,
      body: result,
    });

    return {};
  }
);

export const fetchStatus = createAsyncThunk<Status[]>(
  'jornada/pedido/grupo/status',
  async () => {
    const response = await clientJourney.get<{
      contadores: ChipStatusCountResponse[];
    }>({
      action: 'listas-envio/contador-lista-envio',
      loading: false,
    });
    if (response && response.data) {
      const { contadores } = response.data;
      const chipStatus = contadores.map((count, index) => {
        const { status, total } = count;
        const chipStatus: Status = {
          id: index,
          count: total,
          label: status,
        };
        chipStatus.color = DeparaStatus[status];
        return chipStatus;
      });
      return chipStatus;
    }

    return [];
  }
);

export const fetchGroupList = createAsyncThunk<
  GroupTablePagination<RequestGroupGrid>,
  { page?: number; rowsPerPage?: number; name?: string }
>('jornada/pedido/grupo/lista', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState() as GlobalStore;
  const pageValue = pagination.page
    ? pagination.page
    : state.requestGroup.pagination.page;
  const rowsPerPageValue = pagination.rowsPerPage
    ? pagination.rowsPerPage
    : state.requestGroup.pagination.rowsPerPage;
  const result = await fetchRequests({
    page: pageValue,
    rowsPerPage: rowsPerPageValue,
    name: pagination.name,
  });
  return result;
});

export const fetchList = createAsyncThunk<EditRequestList, string>(
  'jornada/pedido/lista',
  async (id) => {
    const response = (await clientJourney.get<any>({
      action: 'listas-envio/obter-lista-envio',
      params: { id },
    })) as any;
    if (response && response.data && response.success) {
      const { nome, perfil, fluxo, anexosFluxo, anexos } = response.data;
      const files: EditFile[] = convertFiles(anexos);
      const defaultFiles: EditFile[] = convertFiles(anexosFluxo);
      const flow: ComboBoxModel = { id: fluxo.id, name: fluxo.nome };
      const profile: ComboBoxModel = { id: perfil.id, name: perfil.nome };
      return { files, defaultFiles, flow, profile, name: nome, id };
    }
    return {} as EditRequestList;
  }
);

export const fetchQuantity = createAsyncThunk<number, RequestQuantity>(
  'jornada/pedido/lista/quantidade',
  async (type) => {
    const status = type.status.filter((e) => e.checked).map((e) => e.id);
    const response = await clientJourney.get<{ quantidade: number }>({
      action: `listas-envio/quantidade-itens-lista-reenvio`,
      params: { id: type.id, status },
    });
    if (response && response.status === 200) {
      return response.data.quantidade;
    }
    return 0;
  }
);

export const resend = createAsyncThunk<object, RequestQuantity>(
  'jornada/pedido/lista/reenviar',
  async (type) => {
    const status = type.status.filter((e) => e.checked).map((e) => e.id);
    await clientJourney.put({
      action: `listas-envio/reenviar-lista`,
      body: { id: type.id, status },
    });
    return {};
  }
);

export const cancelList = createAsyncThunk<object, string>(
  'jornada/pedido/lista/cancelar',
  async (id) => {
    await clientJourney.put({
      action: 'listas-envio/cancelar-envio-lista',
      body: { id },
    });
    return {};
  }
);

export const sendList = createAsyncThunk<object, string>(
  'jornada/pedido/lista/enviar',
  async (id) => {
    await clientJourney.put({
      action: 'listas-envio/enviar-lista',
      body: { id },
    });
    return {};
  }
);

export const requestGroupSlice = createSlice({
  name: 'requestGroup',
  initialState: { ...initialState },
  reducers: {
    changeViewPagination: (state) => {
      state.isTableView = !state.isTableView;
    },
    changeSelectedRequestGroup: (
      state,
      action: PayloadAction<RequestGroupGrid>
    ) => {
      state.selectRequestGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createList.fulfilled, () => {
      notify('Lista criada com sucesso.');
    });
    builder.addCase(editList.fulfilled, () => {
      notify('Lista editada com sucesso.');
    });
    builder.addCase(cancelList.fulfilled, () => {
      notify('Lista cancelada com sucesso.');
    });
    builder.addCase(sendList.fulfilled, () => {
      notify('Lista enviada com sucesso.');
    });
    builder.addCase(resend.fulfilled, () => {
      notify('Solicitações reenviadas com sucesso.');
    });
    builder.addCase(fetchGroupList.fulfilled, (state, { payload }) => {
      const { count, page, rowsPerPage } = payload;
      state.pagination = { count, page, rowsPerPage };
    });
    builder.addCase(fetchReceiver.fulfilled, (state, { payload }) => {
      const { count, page, rowsPerPage } = payload;
      state.paginationReceiver = { count, page, rowsPerPage };
    });
  },
});

export const selectGroupListPagination = (state: GlobalStore) =>
  state.requestGroup.pagination;

export const selectReceiverPagination = (state: GlobalStore) =>
  state.requestGroup.paginationReceiver;

export const selectPaginationView = (state: GlobalStore) =>
  state.requestGroup.isTableView;

export const selectRequestGroup = (state: GlobalStore) =>
  state.requestGroup.selectRequestGroup;

export const { changeViewPagination, changeSelectedRequestGroup } =
  requestGroupSlice.actions;

export default requestGroupSlice.reducer;
