import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
export const UploadedFilesContainer = styled.div`
  margin-bottom: 16px;
`;

export const UploadedFileItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
`;

export const File = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify ?? 'space-between'};
  align-items: center;
  min-height: 48px;
  padding: 9px 15px;
  gap: 15px;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const IconPdf = styled.span`
  svg {
    width: 18px;
    height: 19.89px;
    margin-right: 8px;
  }
`;

export const MessageContainer = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CloseButton = styled(IconButton)`
  margin-right: 16px;
  z-index: 1;
`;

export const UploadButtonContainer = styled(Button)`
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  label {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    text-transform: none;
  }
`;

export const UploadFile = styled.input`
  display: none;
`;
