import {
  CLICK,
  CLICKS,
  OPEN,
  SELECT,
  UPDATE,
} from '@constants/analytics-action';

const SEND_REQUEST = '[ENVIAR SOLICITAÇÃO]';
const REQUEST = '[SOLICITAÇÕES]';
const DETAIL = '[DRAWER DETALHES]';

export const EVENTS = {
  CLICK_SEND_REQUEST: {
    eventCategory: `${SEND_REQUEST}`,
    eventAction: CLICKS,
  },
  CLICK_ADD_CLIENT: {
    eventCategory: `${SEND_REQUEST}[INCLUIR NOVO CLIENTE]`,
    eventAction: CLICKS,
  },
  CLICK_CONCLUDE_REQUEST: {
    eventCategory: `${SEND_REQUEST}[FINALIZAR ENVIO]`,
    eventAction: CLICKS,
  },
  CLICK_OPEN_SENT_REQUESTS_DETAIL: {
    eventCategory: `${REQUEST}${DETAIL}`,
    eventAction: OPEN,
  },
  CLICK_KEBAB_SENT_REQUESTS_DETAIL: {
    eventCategory: `${REQUEST}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Kebab',
  },
  CLICK_COPY_SENT_REQUESTS_DETAIL: {
    eventCategory: `${REQUEST}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Copiar',
  },
  CLICK_SYNC_SENT_REQUESTS_DETAIL: {
    eventCategory: `${REQUEST}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Reenviar',
  },
  CLICK_SEND_LINK_WHATSAPP_REQUESTS_DETAIL: {
    eventCategory: `${REQUEST}${DETAIL}[ENVIAR LINK WHATSAPP]`,
    eventAction: CLICKS,
  },
  CLICK_CANCEL_SENT_REQUESTS_DETAIL: {
    eventCategory: `${REQUEST}${DETAIL}`,
    eventAction: CLICKS,
    eventLabel: 'Cancelar',
  },
  CLICK_COPY_PROTOCOL_MODAL: {
    eventCategory: `${REQUEST}[MODAL PROTOCOLO]`,
    eventAction: CLICKS,
    eventLabel: 'Copiar',
  },
  CLICK_SEND_LINK_WHATSAPP: {
    eventCategory: `${REQUEST}[ENVIAR LINK WHATSAPP]`,
    eventAction: CLICKS,
    eventLabel: (unique: boolean) => (unique ? 'Único' : 'Múltiplo'),
  },
  CLICK_REQUEST_STATUS_CARD: {
    eventCategory: (status: string) =>
      `${REQUEST}[CARD ${status
        .replaceAll(/[^a-zA-Z0-9\s]+/g, '')
        .toUpperCase()}]`,
    eventAction: CLICKS,
  },
  CLICK_UPDATE_CARD: {
    eventCategory: `${REQUEST}`,
    eventAction: UPDATE,
  },
  CLICK_SENT_REQUEST_TAB: {
    eventCategory: '[SOLICITAÇÕES ENVIADAS]',
    eventAction: CLICK,
  },
  CLICK_FINALIZED_REQUEST_TAB: {
    eventCategory: '[SOLICITAÇÕES FINALIZADAS]',
    eventAction: CLICK,
  },
  SELECT_OPTION_FILTER: {
    eventCategory: `${REQUEST}[FILTROS]`,
    eventAction: SELECT,
  },
  SELECT_STATUS_FILTER: {
    eventCategory: `${REQUEST}[FILTROS][STATUS]`,
    eventAction: SELECT,
  },
};
