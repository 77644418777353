import React, { useEffect } from 'react';

import CustomNavBar from '@src/components/CustomNavBar';
import logo from 'assets/logo.svg';
import { Content } from './styles';
import HashInformation from './HashInformation';
import SignatureStamp from './SignatureStamp';
import { useSelector } from 'react-redux';
import {
  selectAuthenticatorCode,
  selectCheckerCode,
  selectSignatures,
} from '@src/stores/hashValidator';
import ValidateAnotherButton from '../components/ValidateAnotherButton';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const Signatures = () => {
  const history = useHistory();
  const authenticatorCodeValue = useSelector(selectAuthenticatorCode);
  const checkerCodeValue = useSelector(selectCheckerCode);
  const signaturesValue = useSelector(selectSignatures);
  const signatures = () => {
    return signaturesValue.map((signature) => {
      return <SignatureStamp {...signature} />;
    });
  };

  useEffect(() => {
    if (signaturesValue.length === 0) {
      history.push('/validar-hash');
    }
  }, [history, signaturesValue.length]);

  return (
    <Container maxWidth="xl">
      <CustomNavBar logo={logo} position="absolute">
        <Box mt="5px" mb="5px">
          <ValidateAnotherButton outlined />
        </Box>
      </CustomNavBar>
      <Content>
        <HashInformation
          checkerCode={checkerCodeValue}
          authenticatorCode={authenticatorCodeValue}
        />
        <Box mt={4}>
          <Grid container spacing={2} style={{ marginBottom: 50 }}>
            {signatures()}
          </Grid>
        </Box>
      </Content>
    </Container>
  );
};

export default Signatures;
