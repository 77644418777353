import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import URL_IMAGES from '@src/constants/images';
import { StyledPresentationText } from './Welcome.styles';

const Welcome = () => {
  return (
    <Grow in timeout={450}>
      <Box padding={2} width="100%" boxShadow={3}>
        <Box display="flex" justifyContent="center" width="100%" mt={5}>
          <img
            height={94}
            src={URL_IMAGES.WELCOME_LOGO}
            alt="Autentica"
            data-testid="simplifica-icon"
          />
        </Box>
        <Box mt={2} mb={3}>
          <Typography align="center" variant="h5">
            Bem-vindo(a) ao Assertiva Autentica!
          </Typography>
        </Box>
        <Box maxWidth="755px" margin="0 auto" px={2}>
          <StyledPresentationText align="center" data-testid="welcome-text">
            Faça onboarding de clientes de forma totalmente digital, formalize
            propostas, valide identidades e previna-se de fraudes com Biometria
            Facial.
          </StyledPresentationText>
        </Box>
        <Box display="flex" justifyContent="center" width="100%" mt={4} mb={5}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/solicitacoes"
            data-testid="validation-requests-btn"
          >
            SOLICITAÇÕES
          </Button>
        </Box>
      </Box>
    </Grow>
  );
};

export default Welcome;
