import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPartDetails, getPartResult } from '@stores/request';

import RequestDataTabContext from './request-data-tab-context';

const defaultState = {
  tabIsVisible: false,
};

const getDetail = async (id, dispatch, setTabState) => {
  const result = await dispatch(getPartDetails(id));
  if (!result.type.includes('rejected')) {
    setTabState({ tabIsVisible: true });
  } else {
    setTabState({ tabIsVisible: false });
  }
};

const getResult = async (id, dispatch, setTabState) => {
  const result = await dispatch(getPartResult(id));
  if (!result.type.includes('rejected')) {
    setTabState({ tabIsVisible: true });
  } else {
    setTabState({ tabIsVisible: false });
  }
};

const RequestDataTabProvider = (props) => {
  const dispatch = useDispatch();
  const [tabState, setTabState] = useState(defaultState);

  const openTabHandler = (id, isDetail) => {
    if (isDetail) {
      (async () => {
        await getResult(id, dispatch, setTabState);
        setTabState({ tabIsVisible: true });
      })();
    } else {
      (async () => {
        await getDetail(id, dispatch, setTabState);
        setTabState({ tabIsVisible: true });
      })();
    }
  };

  const closeTabHandler = () => {
    setTabState({ tabIsVisible: false });
  };

  const tabContext = {
    tabIsVisible: tabState.tabIsVisible,
    openTab: openTabHandler,
    closeTab: closeTabHandler,
  };

  return (
    <RequestDataTabContext.Provider value={tabContext}>
      {props.children}
    </RequestDataTabContext.Provider>
  );
};

export default RequestDataTabProvider;
