import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '@infra/http-client';
import {
  ILocalizeSearchResult,
  RequestInitialState,
  Score,
  ScoreSectionTitle,
  ScoreSectionType,
} from '@src/models/stores/request/request-initial-state';
import { groupBy } from '@src/utils/array-utils';
import { dispatchFetchFunctionalities } from '../functionalities';
import { LOCALIZE_FUNCTIONALITY } from '@src/constants/functionality-tags';

const deparaScoreSectionTitle = {
  provaVida: ScoreSectionTitle.ProofLife,
  biometriaFacial: ScoreSectionTitle.FacialBiometric,
  documento: ScoreSectionTitle.Document,
};

const client = HttpClient('');

export const getPartDetails = createAsyncThunk<any, string>(
  'getPartDetails',
  async (id) => {
    const result = await client.get({
      action: 'pedidos/detalhe',
      params: { pedidoId: id },
    });
    return result.data;
  }
);

export const getPartResult = createAsyncThunk<any, string>(
  'getPartResult',
  async (id, { dispatch }) => {
    const resultParts = await client.get<any>({
      action: 'pedidos/resultado',
      params: { pedidoId: id },
    });

    const localizeSearchErrorsResult = await client.get<any>({
      action: 'configuracoes/localize/errors',
    });

    const functionalities = await dispatchFetchFunctionalities(dispatch);
    const localizeSearchFunctionalitie =
      functionalities && functionalities.length > 0
        ? functionalities.find((fun) => fun.tag === LOCALIZE_FUNCTIONALITY)
        : null;

    return {
      ...resultParts.data,
      localizeSearchErrors: localizeSearchErrorsResult.data.errorsLocalize,
      isLocalizeSearchAbled: localizeSearchFunctionalitie
        ? localizeSearchFunctionalitie.ativo
        : false,
    };
  }
);

export const getPartStatus = createAsyncThunk<any, string>(
  'getPartStatus',
  async (id) => {
    const result = await client.get<any>({
      action: 'partes/status-parte',
      params: { parteId: id },
    });
    return { id, ...result };
  }
);

export const makeLocalizeSearch = createAsyncThunk<any, string>(
  'makeLocalizeSearch',
  async (id) => {
    const result = await client.get<any>({
      action: 'partes/consultar-localize',
      params: { parteId: id },
    });
    return { id, ...result };
  }
);

const convertLocalizeResultErrorsData = (error: any) => {
  return {
    id: error.id,
    title: error.titulo,
    description: error.descricao,
    icon: error.icone,
    color: error.cor,
    action: error?.acao,
  };
};

const convertRequestResponsePart = (part: any) => {
  const documents = part.documentosAnexos.map((document) => {
    return {
      name: document.nome,
      url: document.src,
      extension: 'pdf',
      key: document.chave,
      type: document.tipo,
    };
  });

  const documentsAttachedByUser = part.documentosAnexados.map((document) => {
    return {
      name: document.nome,
      url: document.src,
      extension: document.extensao,
      key: document.chave,
      type: document.tipo,
    };
  });

  return {
    id: part.id,
    customerData: convertCustomerData(part.dadosUsuario),
    localizeSearchResult: convertLocalizeResult(part.dadosLocalize),
    attachedDocuments: documents,
    attachedByUserDocuments: documentsAttachedByUser,
    scores: convertMatches(part.matches ? part.matches : part.scores, part.id),
    generalScore: part.score
      ? convertGeneralScore(part.score, part.documentosColetados)
      : { classification: 0 },
    collectDocuments: convertCollectDocuments(part),
    digitalSignature: converterDigitalSignature(part.assinaturaDigital),
    messageStatus: part.mensagemStatus,
    status: part.status,
  };
};

const convertMatches = (matches, partId) => {
  const matchesGroupBy = groupBy(matches, 'secao');

  const keys = Object.keys(matchesGroupBy);

  const mappedScores = keys.reduce<Score[]>((arr, el) => {
    const title = deparaScoreSectionTitle[el];
    if (title) {
      const matches = matchesGroupBy[el];
      const firstMatchValid = matches.find((m) => !!m.content.url);
      const match = {
        key: firstMatchValid.content.chave,
        partId,
        type: firstMatchValid.content.tipo,
        src: firstMatchValid.content.url,
        title: title as ScoreSectionTitle,
        section: el as ScoreSectionType,
        matches: matches.map((m) => {
          return {
            name: m.nome,
            status: m.status,
            message: m.mensagem,
            description: m.descricao,
          };
        }),
      };
      return arr.concat(match);
    }
    return arr;
  }, []);
  return mappedScores;
};

const convertOcr = (ocrExtractions, name) => {
  const hasOcr = ocrExtractions.some(
    (extraction) => extraction.documento === name
  );
  if (hasOcr) {
    const result = ocrExtractions
      .filter((extraction) => extraction.documento === name)
      .map((extractionOcr) => {
        const {
          descricao: description,
          documento: document,
          campos,
        } = extractionOcr;
        const fields = campos
          .map((campo) => {
            const {
              confianca: confidence,
              label,
              nome: name,
              valor: value,
            } = campo;
            return { confidence, label, name, value };
          })
          .filter((e) => e.name !== 'foto');
        const extraction = {
          document,
          description,
          datas: { fields },
        };
        return extraction;
      });
    return result[0];
  }
};

const convertCollectDocuments = (response) => {
  const { documentosColetados: documents, extracoesOCR: ocrExtractions } =
    response;
  const result = documents.map((item) => {
    return {
      src: item.url,
      status: item.status,
      mensagem: item.mensagem,
      extraction: convertOcr(ocrExtractions, item.nome),
      name: item.nome,
      key: item.chave,
      type: item.tipo,
      partId: response.id,
    };
  });
  return result;
};

const converterDigitalSignature = (digitalSignature) => {
  const { dataHora: dataTime, assinaturas } = digitalSignature;
  const signatures = assinaturas.map((signature) => {
    const {
      nome: name,
      documento: document,
      ip,
      dataHora: dataTime,
      geolocalizacao: geolocalization,
    } = signature;
    return { name, document, ip, dataTime, geolocalization };
  });
  return { dataTime, signatures };
};

const convertCustomerData = (customerData) => {
  const result = customerData.map((field) => {
    const { tipo, nome, etiqueta, valor } = field;
    return { type: tipo, name: nome, description: etiqueta, value: valor };
  });
  return { campos: result, nome: 'dados-usuario' };
};

const convertLocalizeResult = (
  localizeResult
): ILocalizeSearchResult | null => {
  if (!localizeResult) {
    return null;
  }

  if (localizeResult.idErro) {
    return { campos: [], idErro: localizeResult.idErro };
  }

  const result = localizeResult.campos.map((field) => {
    const { nome, etiqueta, valor, chip } = field;
    return {
      name: nome,
      label: etiqueta,
      value: valor,
      chip: chip
        ? { label: chip.etiqueta, color: chip.cor, description: chip.descricao }
        : undefined,
      order: nome === 'nome' ? 1 : undefined,
    };
  });

  return { campos: result, idErro: null };
};

const convertGeneralScore = (score, documents) => {
  const result = {
    score: Math.trunc(score.scoreTotal),
    url: documents.find((d) => d.nome === 'rostoSimples')?.url,
    appliedScore: score.valorScoreRegraAplicado,
    configuredScore: score.valorScoreRegraVigente,
    approvalType: score.tipoAprovacao,
    classification: score.classificacaoId !== null ? score.classificacaoId : 0,
  };

  return result;
};

export const requestDataModalSlice = createSlice({
  name: 'requestDataModal',
  initialState: {
    requestDetail: {},
    requestResponse: {},
  } as RequestInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPartDetails.fulfilled, (state: any, action: any) => {
      const response = action.payload;

      state.requestDetail.requestId = response.pedidoId;
      state.requestDetail.tutorialURL = response.tutorialURL;
      state.requestDetail.tutorialDownloadURL = response.tutorialDownloadURL;
      state.requestDetail.parts = response.partes.map((part) => {
        const documents = part.documentosAnexos.map((document) => {
          return {
            name: document.nome,
            url: document.url,
            extension: 'pdf',
            key: document.chave,
            type: document.tipo,
          };
        });

        return {
          id: part.id,
          protocol: part.protocolo,
          status: part.status,
          collected: part.coletado,
          customerData: convertCustomerData(part.dadosUsuario),
          attachedDocuments: documents,
          url: part.url,
        };
      });
    });

    builder.addCase(getPartResult.fulfilled, (state: any, action: any) => {
      const response = action.payload;
      state.requestResponse = {
        requestId: response.pedidoId,
        parts: response.partes.map((part) => convertRequestResponsePart(part)),
        localizeSearchErrors: response.localizeSearchErrors.map((error) =>
          convertLocalizeResultErrorsData(error)
        ),
        isLocalizeSearchAbled: response.isLocalizeSearchAbled,
      };
    });

    builder.addCase(getPartStatus.fulfilled, (state: any, action: any) => {
      const response = action.payload;

      const partIndex = state.requestResponse.parts.findIndex(
        (part) => part.id === response.id
      );
      if (partIndex !== -1) {
        const parts = [...state.requestResponse.parts];
        parts[partIndex].status = response.data.status;
        parts[partIndex].messageStatus = response.data.mensagemStatus;
        state.requestResponse.parts = parts;
      }
    });

    builder.addCase(makeLocalizeSearch.fulfilled, (state: any, action: any) => {
      const response = action.payload;

      const partIndex = state.requestResponse.parts.findIndex(
        (part) => part.id === response.id
      );

      if (partIndex !== -1) {
        const parts = [...state.requestResponse.parts];
        parts[partIndex].localizeSearchResult = convertLocalizeResult(
          response.data
        );
        state.requestResponse.parts = parts;
      }
    });
  },
});

export const selectRequestDetail = (state) =>
  state.requestDataModal.requestDetail;

export const getPartUrlPhoneName = (part) => ({
  url: part?.url,
  phone: part?.customerData?.campos?.find?.(
    (campo) => campo?.name === 'celular'
  )?.value,
  name: part?.customerData?.campos?.find?.((campo) => campo?.name === 'nome')
    ?.value,
});

export const selectRequestDetailPartsWhats = (state) =>
  state.requestDataModal.requestDetail?.parts?.map(getPartUrlPhoneName);

export const selectPartId = (state) => state.requestDataModal.partId;

export const selectRequestResponse = (state) =>
  state.requestDataModal.requestResponse;

export const selectLocalizeSearchErrors = (state) =>
  state.requestDataModal.requestResponse.localizeSearchErrors;

export const selectIsLocalizeSearchEnabled = (state) =>
  state.requestDataModal.requestResponse.isLocalizeSearchAbled;

export default requestDataModalSlice.reducer;
