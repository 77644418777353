import React from 'react';
import { BoldSpan, SignatureText } from '../styles';
import CustomCheckCircleIcon from '@assets/icons/CustomCheckCircleIcon';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

interface HashInformationProps {
  authenticatorCode: string;
  checkerCode: string;
}

const HashInformation: React.FC<HashInformationProps> = ({
  authenticatorCode,
  checkerCode,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={1} style={{ maxWidth: 27 }}>
        <Box style={{ paddingTop: 32 }}>
          <CustomCheckCircleIcon fill="#5DB633" width={38} height={38} />
        </Box>
      </Grid>
      <Grid item xs={11} style={{ paddingTop: 16, paddingLeft: 20 }}>
        <SignatureText id="authenticator-code-text">
          <BoldSpan>Cód autenticador:</BoldSpan> {authenticatorCode}
        </SignatureText>
        <SignatureText id="checker-code-text">
          <BoldSpan>Cód verificador:</BoldSpan> {checkerCode}
        </SignatureText>
        <SignatureText id="information-text">
          Este documento é válido e foi assinado pelo(s) solicitantes abaixo:
        </SignatureText>
      </Grid>
    </Grid>
  );
};

export default HashInformation;
