import React from 'react';

const RequestDataTabContext = React.createContext({
  tabIsVisible: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  openTab: (id, isDetail = false) => {},
  closeTab: () => {},
});

export default RequestDataTabContext;
