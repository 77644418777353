export enum CaptureEnum {
  Normal = 'captura',
  Face = 'foto-rosto',
  Document = 'foto-documento',
  Proof = 'comprovante',
}

export enum CollectEnum {
  Self = 'provaVida',
  Identity = 'identidade',
  Rent = 'renda',
  Address = 'endereco',
  MaritalStatus = 'estadoCivil',
}

export enum ArtifacType {
  Info = 'informativo',
  Collector = 'coletor',
  Conclusion = 'conclusao',
}

export enum ArtifactEnum {
  Presentation = 'apresentacao',
  Terms = 'termos-condicoes',
  SlidePresentation = 'apresentacoes-slide',
  Instructions = 'instrucao',
  Annex = 'anexo-documentos',
  Preparing = 'preparacao',
  Feedback = 'envio-finalizacao',
  Proposal = 'proposta',
  CaptureFace = 'foto-rosto',
  CaptureDocument = 'foto-documento',
  CaptureProof = 'comprovante',
  Question = 'questoes',
}

export enum ArtifactItemEnum {
  Blank = 'em-branco',
  Terms = 'termos-condicoes',
  Annex = 'anexo-documentos',
  Question = 'questoes',
  Presentation = 'apresentacao',
  Presentations = 'apresentacoes-slide',
  Instructions = 'instrucao',
  Feedback = 'envio-finalizacao',
  Proposal = 'proposta',
  Capture = 'captura',
  CaptureFace = 'foto-rosto',
  CaptureDocument = 'foto-documento',
  CaptureProof = 'comprovante',
  Preparing = 'preparacao',
}

export const getArtifactItem = (key: string) => {
  return artifactMap[key];
};

const artifactMap = {
  preparacao: ArtifactItemEnum.Preparing,
  comprovante: ArtifactItemEnum.CaptureProof,
  'foto-documento': ArtifactItemEnum.CaptureDocument,
  'foto-rosto': ArtifactItemEnum.CaptureFace,
  captura: ArtifactItemEnum.Capture,
  proposta: ArtifactItemEnum.Proposal,
  'envio-finalizacao': ArtifactItemEnum.Feedback,
  instrucao: ArtifactItemEnum.Instructions,
  'apresentacoes-slide': ArtifactItemEnum.Presentations,
  apresentacao: ArtifactItemEnum.Presentation,
  questoes: ArtifactItemEnum.Question,
  'anexo-documentos': ArtifactItemEnum.Annex,
  'termos-condicoes': ArtifactItemEnum.Terms,
  'em-branco': ArtifactItemEnum.Blank,
};
