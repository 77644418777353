import { CLICK } from '@constants/analytics-action';

const SIDEBAR = '[SIDEBAR]';

export const EVENTS = {
  CLICK_FLOW: {
    eventCategory: `${SIDEBAR}[SOLICITAÇÕES]`,
    eventAction: CLICK,
  },
  CLICK_BUILDER: {
    eventCategory: `${SIDEBAR}[CONSTRUTOR]`,
    eventAction: CLICK,
  },
  CLICK_CONFIGURATION: {
    eventCategory: `${SIDEBAR}[CONFIGURAÇÃO]`,
    eventAction: CLICK,
    SIDEBAR,
  },
};
