import { createAsyncThunk } from '@reduxjs/toolkit';
import { notify } from '@src/utils/notification';
import HttpClient from '@infra/http-client';
import { iCompanyRegistration } from '@src/models/stores/registration/company-registration';

const clientJourney = HttpClient('');

export const postCompany = createAsyncThunk<boolean, iCompanyRegistration>(
  'jornadas/configuracoes/sms',
  async (values) => {
    const response = await clientJourney.put({
      action: 'configuracoes/sms',
      body: {
        nomeEmpresa: values.sms,
      },
    });
    if (response.status === 200) {
      notify('Cadastro realizado com sucesso');
    }
    return response.status === 201;
  }
);

export const fetchConfigSms = createAsyncThunk<string>(
  'jornadas/configuracoes/sms',
  async () => {
    const result = await clientJourney.get<{ nomeEmpresa: string }>({
      loading: false,
      action: 'configuracoes/sms',
    });

    if (result) {
      return result.data.nomeEmpresa;
    }
    return '';
  }
);

export const dispatchFetchConfigSms = (dispatch) =>
  dispatch(fetchConfigSms()).unwrap();
