import { createAsyncThunk } from '@reduxjs/toolkit';
import { notify } from '@src/utils/notification';
import HttpClient from '@infra/http-client';
import { IFunctionality } from '@src/models/stores/functionalities/functionality';

const clientJourney = HttpClient('');

export const putFunctionality = createAsyncThunk<boolean, IFunctionality[]>(
  'jornadas/configuracoes/funcionalidades',
  async (values) => {
    const response = await clientJourney.put({
      action: 'configuracoes/funcionalidades',
      body: {
        funcionalidades: values.map((f) => ({ tag: f.tag, ativo: f.ativo })),
      },
    });
    if (response.status === 200) {
      notify('Funcionalidades configuradas com sucesso');
    }
    return response.status === 201;
  }
);

export const fetchFunctionalities = createAsyncThunk<IFunctionality[]>(
  'jornadas/configuracoes/funcionalidades',
  async () => {
    const result = await clientJourney.get<{
      funcionalidades: IFunctionality[];
    }>({
      loading: false,
      action: 'configuracoes/funcionalidades',
    });

    if (result?.data) {
      return result.data?.funcionalidades;
    }
    return [];
  }
);

export const dispatchFetchFunctionalities = (dispatch) =>
  dispatch(fetchFunctionalities()).unwrap();
