import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '@infra/http-client';
import stores from '../index';
import {
  sendLink,
  setPedidoId,
  setProtocolo,
} from '@pages/ValidationRequests/store';
import { sendAnalytics } from '@src/utils/analytics';
import { EVENTS } from '@pages/ValidationRequests/constants/analytics';
import { getPartDetails } from '../request';

const client = HttpClient('');

const convertFields = (fields) => {
  const result = fields
    .map((field) => {
      if (field) {
        const { id, value } = field;
        return { id, valor: value };
      }
      return null;
    })
    .filter((e) => e !== null);
  return result;
};

const convertGlobalFiles = (parts) => {
  if (parts.length > 0) {
    const result = parts
      .reduce((arr, parts) => {
        arr = arr.concat((parts as any).files);
        return arr;
      }, [])
      .reduce(
        (obj, file) => {
          if (file.isGlobal) {
            if (file.isDefault) {
              obj.anexosFluxo = obj.anexosFluxo.concat({ id: file.id });
            } else
              obj.anexos = obj.anexos.concat({
                artefato: file.artefact,
                nome: file.name,
                extensao: file.extension,
                chave: file.key,
              });
          }
          return obj;
        },
        { anexosFluxo: [], anexos: [] }
      );
    return result;
  }
};

const convertPart = (part) => {
  const campos = convertFields(part.fields);

  const anexos = part.files
    .filter((file) => !file.isDefault && !file.isGlobal)
    .map((file) => {
      return {
        artefato: file.artefact,
        nome: file.name,
        extensao: file.extension,
        chave: file.key,
      };
    });

  const anexosFluxo = part.files
    .filter((file) => file.isDefault && !file.isGlobal)
    .map((file) => {
      return { id: file.id };
    });
  return {
    perfilId: part.profileId,
    fluxoId: part.flow,
    campos,
    anexos,
    anexosFluxo,
  };
};

const _saveRequests = createAsyncThunk<any, any>(
  'saveRequests',
  async (requests) => {
    const { profileId: perfilId, flow: fluxoId, fields } = requests.state;
    const campos = convertFields(fields);

    const anexos = requests.file
      .filter((file) => !file.isDefault)
      .map((file) => {
        return {
          artefato: file.artefact,
          nome: file.name,
          extensao: file.extension,
          chave: file.key,
        };
      });

    const anexosFluxo = requests.file
      .filter((file) => file.isDefault)
      .map((file) => {
        return file.id;
      });

    const request = {
      partes: [{ perfilId, fluxoId, campos, anexos, anexosFluxo }],
    };

    const result = await client.post({
      action: 'pedidos',
      body: request,
      loading: true,
    });

    if (!result) {
      return false;
    }
    const { partes } = result.data;
    if (partes) {
      stores.dispatch(sendLink(partes[0].protocolo));
    }
    return true;
  }
);

export const sendRequest = createAsyncThunk<any>(
  'sendRequest',
  async (_, { getState }) => {
    let parts = (getState() as any).requestForm.parts;
    const convertedGlobalFiles = convertGlobalFiles(parts);

    if (convertedGlobalFiles.anexos.length > 0) {
      parts = parts.map((part) => {
        return {
          ...part,
          files: part.files.filter(
            (file) =>
              file.isGlobal ||
              !convertedGlobalFiles.anexos.find((x) => x.nome === file.name)
          ),
        };
      });
    }

    if (convertedGlobalFiles.anexosFluxo.length > 0) {
      parts = parts.map((part) => {
        return {
          ...part,
          files: part.files.filter(
            (file) =>
              file.isGlobal ||
              !convertedGlobalFiles.anexosFluxo.some((x) => x.id === file.id)
          ),
        };
      });
    }

    const convertedParts = parts.map((part) => convertPart(part));
    const result = await client.post({
      action: 'pedidos',
      body: { anexosGlobais: convertedGlobalFiles, partes: convertedParts },
      loading: true,
    });

    sendAnalytics({
      ...EVENTS.CLICK_CONCLUDE_REQUEST,
      eventLabel: convertedParts.length.toString(),
    });

    if (!result) {
      return false;
    }

    const { partes, protocolo, pedidoId } = result.data;

    await stores.dispatch(getPartDetails(pedidoId));

    if (partes) {
      stores.dispatch(setProtocolo(protocolo));
      stores.dispatch(setPedidoId(pedidoId));
    }

    return true;
  }
);

export const _getFilesDefault = createAsyncThunk<any, any>(
  'getFilesDefault',
  async (id) => {
    const result = await client.get({
      action: 'fluxos/anexos',
      params: { fluxoId: id },
    });
    return result;
  }
);

export const saveRequests = async (filters) => {
  return stores.dispatch(_saveRequests(filters));
};

export const getFilesDefault = async (value) => {
  const response = await stores.dispatch(_getFilesDefault(value));
  if (response && response.payload) {
    const result = response.payload.data;
    if (result) {
      const files = result.anexos;
      const defaultFiles = files?.map((e) => {
        return {
          id: e.id,
          name: e.nome,
          url: e.url,
          extension: e.extensao,
          isDefault: true,
        };
      });
      return defaultFiles;
    }
  }

  return [];
};

export const addNewPart = createAsyncThunk<any, any>(
  'addNewPart',
  async (
    { profileId, flow, fields, files, editedIndex },
    { getState, dispatch }
  ) => {
    fields = fields.filter((x) => x !== null);
    const keyField = fields.find((x) => x.name === 'cpf' || x.name === 'cnpj');
    if (keyField) {
      const state = getState() as any;
      const sameKeyFieldValue = state.requestForm.parts.filter(
        (part, index) =>
          index !== editedIndex &&
          part.fields.some(
            (field) =>
              (field.name === 'cpf' || field.name === 'cnpj') &&
              keyField.value === field.value
          )
      );
      if (sameKeyFieldValue.length > 0) {
        return `Já existe um destinatário com o ${keyField.description} informado.`;
      }
    }

    if (editedIndex >= 0) {
      dispatch(
        editParts({
          index: editedIndex,
          part: { profileId, flow, fields: fields, files },
        })
      );
      return;
    }

    dispatch(
      addParts({
        profileId,
        flow,
        fields: fields,
        files,
      })
    );
  }
);

export const requestFormSlice = createSlice({
  name: 'requestForm',
  initialState: {
    protocol: '',
    requestId: '',
    parts: [],
  } as any,
  reducers: {
    addParts: (state, action) => {
      state.parts.push(action.payload);
    },
    editParts: (state, action) => {
      state.parts[action.payload.index] = action.payload.part;
    },
    removePart: (state, action) => {
      state.parts.splice(action.payload, 1);
    },
    resetParts: (state) => {
      state.parts = [];
    },
  },
  extraReducers: () => {},
});

export const { addParts, removePart, resetParts, editParts } =
  requestFormSlice.actions;

export const selectRequestParts = (state) => state.requestForm.parts;

export default requestFormSlice.reducer;
