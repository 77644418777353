export const groupBy = (array, key) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const immutableSplice = (arr, start, deleteCount, ...items) => {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
};

//TODO: Implementar a mesclagem de arrays
export const removeEmpty = (obj) => {
  const result = Object.entries(obj)
    .filter(([, v]) => {
      return v !== '';
    })
    .reduce((acc, [k, v]) => {
      let value = v;
      if (Array.isArray(value)) {
        value = value.map((e) => removeEmpty(e));
      }
      return { ...acc, [k]: value };
    }, {});
  return result;
};
