import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AUNotifier } from '@assertiva/assertiva-ui';
import { nanoid } from 'nanoid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const notify = (message: string, isIcon?: boolean) => {
  const actionButton = (key) => (
    <Button
      color="secondary"
      size="small"
      onClick={() => {
        AUNotifier.closeNotificationByKey(key);
      }}
    >
      <Typography
        style={{
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '15px',
          textAlign: 'right',
          letterSpacing: '0.5px',
          color: '#BB86FC',
        }}
      >
        ENTENDI
      </Typography>
    </Button>
  );

  const actionIcon = (key) => {
    return (
      <>
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => {
            AUNotifier.closeNotificationByKey(key);
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
    );
  };

  AUNotifier.notify({
    message,
    options: {
      preventDuplicate: true,
      autoHideDuration: 3000,
      variant: 'default',
      action: isIcon ? actionIcon : actionButton,
    },
  });
};

export const notifyWithInterval = (
  actionBeforeTime: () => void,
  time: number
) => {
  const timeout = setTimeout(() => {
    actionBeforeTime();
  }, time * 1000);

  const handleExecute = (key) => {
    clearTimeout(timeout);
    actionBeforeTime();
    AUNotifier.closeNotificationByKey(key);
  };

  const handleUndo = (key) => {
    clearTimeout(timeout);
    AUNotifier.closeNotificationByKey(key);
    notify('O reset da lista foi desfeito.', true);
  };

  const Mensagem: React.FC<{ id: string }> = () => {
    const [intervalMessage, setIntervalMessage] = useState(time);

    useEffect(() => {
      const interval = setInterval(() => {
        setIntervalMessage(intervalMessage - 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }, [intervalMessage]);

    if (intervalMessage <= 0) {
      AUNotifier.closeNotificationById(id);
    }

    return <div>Lista de envio resetada com sucesso ({intervalMessage}s)</div>;
  };

  const action = (key) => (
    <>
      <Button
        color="secondary"
        size="small"
        onClick={() => {
          handleUndo(key);
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '15px',
            textAlign: 'right',
            letterSpacing: '0.5px',
            color: '#BB86FC',
          }}
        >
          DESFAZER
        </Typography>
      </Button>
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => {
          handleExecute(key);
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  const id = nanoid();
  AUNotifier.notify({
    id: id,
    message: (<Mensagem id={id} />) as any,
    options: {
      variant: 'default',
      action,
      persist: true,
    },
  });
};
