export interface DynamicField {
  id: string;
  order?: number;
  description?: string;
  label: string;
  name: string;
  maxLength?: string;
  minLength?: string;
  required?: boolean;
  type: Type;
  mask?: string;
  value?: string;
  options?: string[];
  chip?: string;
  chipColor?: string;
  chipDescription?: string;
  customOnChange?: (value: any) => void;
}

export enum Type {
  Combo = 'combobox',
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
}
