import styled from 'styled-components';

export const Content = styled.div`
  margin: auto;
  margin-top: 100px;
  max-height: 352px;
  max-width: 347px;
  text-align: center;
`;

export const NotFoundTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 23px;
  margin-top: 36px;
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
`;

export const NotFoundText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
  margin: auto;
`;
