import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 80px;
`;

export const BoldSpan = styled.span<any>`
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : '#000000')};
`;

export const SignatureText = styled.div<any>`
  font-style: normal;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: 25px;
  letter-spacing: 0.5px;
  overflow-wrap: break-word;
  color: #000000;
  display: ${(props) => (props.display ? props.display : 'block')};
`;
