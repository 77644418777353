import { AuthUtils, useFeatureFlag } from '@assertiva/assertiva-ui';
import { CONFIG_SMS } from '@src/constants/feature-flags';
import { useEffect } from 'react';

export function useConfigSmsFeatureFlag() {
  const { flagsError, flagsReady, getFlag, updateContext } = useFeatureFlag();

  const userId = AuthUtils.getUser()?.id?.toString();

  const shouldHideConfigSms = getFlag(CONFIG_SMS) && flagsReady && !flagsError;

  useEffect(() => {
    updateContext({ userId });
  }, [updateContext, userId]);

  return { shouldHideConfigSms };
}
