import React from 'react';
import { BoldSpan, SignatureText } from '../styles';
import { BlockedTag, FileNameText, Region, SignatureCard } from './styles';
import { SignatureModel } from '@src/models/stores/hash-validator';
import { ReactComponent as Pdf } from 'assets/pdf.svg';
import { File, IconPdf, MessageContainer } from '@src/components/File/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CustomCheckCircleIcon from '@assets/icons/CustomCheckCircleIcon';

const SignatureStamp: React.FC<SignatureModel> = ({
  signed,
  who,
  cpf,
  when,
  where,
  ip,
  sendedFiles,
}) => {
  const files = () => {
    return sendedFiles?.map((file, index) => {
      return (
        <File>
          <MessageContainer style={{ opacity: 0.4 }}>
            <IconPdf>
              <Pdf />
            </IconPdf>
            <FileNameText id={`file-name-${index}`}>{file}</FileNameText>
          </MessageContainer>
        </File>
      );
    });
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
      <SignatureCard borderColor={signed ? '#B1F392' : '#FABC5F'}>
        <Box style={{ padding: '15px 7px 12px 27px' }}>
          <Region>
            <SignatureText display="flex" id="who-text">
              <BoldSpan>Quem: </BoldSpan>
              {who}
              <BlockedTag />
            </SignatureText>
            <SignatureText display="flex" id="cpf-text">
              <BoldSpan>CPF: </BoldSpan>
              {cpf}
              <BlockedTag />
            </SignatureText>
            <SignatureText display="flex" id="when-text">
              <BoldSpan>Quando: </BoldSpan>
              {when}
            </SignatureText>
            <SignatureText display="flex" id="where-text">
              <BoldSpan>Onde: </BoldSpan>
              {signed && where && (
                <>
                  {where}
                  <BlockedTag />
                </>
              )}
            </SignatureText>
            <SignatureText display="flex" id="ip-text">
              <BoldSpan>IP: </BoldSpan>
              {signed && (
                <>
                  {ip}
                  <BlockedTag />
                </>
              )}
            </SignatureText>
          </Region>
          {signed && (
            <Region>
              <SignatureText>
                <BoldSpan>Arquivos enviados</BoldSpan>
              </SignatureText>
              {files()}
            </Region>
          )}
          <Box marginTop={1}>
            <Grid container spacing={0}>
              <Grid item sm={1} style={{ maxWidth: 50 }}>
                <Box>
                  {signed ? (
                    <CustomCheckCircleIcon
                      style={{ marginTop: 2 }}
                      fill="#5DB633"
                    />
                  ) : (
                    <CustomCheckCircleIcon
                      fill="#FABC5F"
                      style={{ marginTop: 2 }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item sm={11}>
                <SignatureText fontSize="12px" id="stamp-status-text">
                  {signed ? (
                    <BoldSpan color="#5DB633">Doc. assinado</BoldSpan>
                  ) : (
                    <BoldSpan color="#FABC5F">Ass. pendente</BoldSpan>
                  )}
                </SignatureText>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </SignatureCard>
    </Grid>
  );
};

export default SignatureStamp;
