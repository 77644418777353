import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '@infra/http-client/anonymous';
import { GetSignaturesInput } from './interfaces/get-signatures-input';
import { setAuthenticatorCode, setCheckerCode, setSignatures } from '.';

const jorneyClient = HttpClient('validacoes');

export const getSignatures = createAsyncThunk<void, GetSignaturesInput>(
  'hashValidator/getSignatures',
  async (
    { history, authenticatorCode, checkerCode, recaptchaResponse },
    { dispatch }
  ) => {
    dispatch(setAuthenticatorCode(authenticatorCode));
    dispatch(setCheckerCode(checkerCode));
    try {
      const result = await jorneyClient.get<any>({
        action: 'obter-assinaturas',
        params: {
          codigoAutenticador: authenticatorCode,
          codigoVerificador: checkerCode,
          recaptchaResponse: recaptchaResponse,
        },
      });
      const signatures = result.data.assinaturas.map((signature) => {
        return {
          signed: signature.assinado,
          who: signature.quem,
          cpf: signature.documento,
          when: signature.quando,
          where: signature.onde,
          ip: signature.ip,
          hash: signature.hash,
          sendedFiles: signature.anexos,
        };
      });
      dispatch(setSignatures(signatures));
      history.push('/validar-hash/resultado');
    } catch (error) {
      history.push('/validar-hash/nao-encontrado');
    }
  }
);
