import { combineReducers } from '@reduxjs/toolkit';
import authenticationReducer from '../authentication-slice';
import newRequestReducer from '../journey/request';
import requestGroupReducer from '../journey/request/group';
import automaticApprovalReducer from '../approval';
import builderReducer from '../builder';
import uploadReducer from '../upload';
import hashValidatorReducer from '../hashValidator';
import RequestsReducer from '@pages/ValidationRequests/store';
import requestDataModalReducer from '../request';
import requestFormReducer from '../request-form';
import layoutReducer from '../layout';

const rootReducer = combineReducers({
  newRequest: newRequestReducer,
  requestGroup: requestGroupReducer,
  authentication: authenticationReducer,
  requests: RequestsReducer,
  requestDataModal: requestDataModalReducer,
  automaticApproval: automaticApprovalReducer,
  builder: builderReducer,
  upload: uploadReducer,
  hashValidator: hashValidatorReducer,
  requestForm: requestFormReducer,
  layout: layoutReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
