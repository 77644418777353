import React, { Suspense, lazy } from 'react';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  AUClientLogin,
  AUProductTrailSuggestion,
  AuthUtils,
  Envs,
  PRODUCT_IDS,
  browserHistory,
} from '@assertiva/assertiva-ui';

import HashValidator from '@src/pages/HashValidator';
import NoHashFound from '@src/pages/HashValidator/NoHashFound';
import Signatures from '@src/pages/HashValidator/Signatures';
import HomePage from '@src/pages/Home';
import RegistrationPage from '@src/pages/Registration';
import RequestDataTabProvider from '@src/pages/ValidationRequests/store/request-data-tab/RequestDataTabProvider';
import { hasJourneyAccess } from '@src/utils/permission-utils';
import { sendAnalytics } from '@utils/analytics';
import { ReactComponent as PlataformIcon } from 'assets/icons/plataform.svg';
import { EVENTS } from './constants/analytics';
import FunctionalitiesPage from '@src/pages/Functionalities/Functionalities';

const ValidationRequests = lazy(() => import('@pages/ValidationRequests'));

const Configuration = lazy(() => import('@pages/Configuration'));

const AutomaticApproval = lazy(() =>
  import('@pages/Configuration/AutomaticApproval')
);

const BuilderV2 = lazy(() => import('@pages/Builder'));
const Flows = lazy(() => import('@pages/Builder/Flows'));
const CreateNewDraft = lazy(() => import('@pages/Builder/Create/Templates'));
const BuilderSandbox = lazy(() => import('@pages/Builder/Sandbox'));

const Container = ({ children }) => {
  return (
    <Box p={5} maxWidth="1188px" margin="auto">
      {children}
    </Box>
  );
};

const getRouters = () => {
  const user = AuthUtils.getUser();

  const hasAdminPermission = user.isAdminProfile() || user.isManagerProfile();

  const hasJourneyPermission = hasJourneyAccess();

  return [
    {
      id: 0,
      exact: true,
      path: '/',
      redirect: '/inicio',
      isPrivate: false,
      component: (props) => (
        <Suspense fallback={<div>Loading...</div>}>
          <AUClientLogin {...props} />
        </Suspense>
      ),
    },
    {
      id: 3,
      exact: true,
      path: '/validar-hash',
      label: 'Validar Hash',
      showInMenu: false,
      isPrivate: false,
      component: (props) => (
        <Suspense fallback={<div />}>
          <HashValidator {...props} />
        </Suspense>
      ),
    },
    {
      id: 4,
      exact: true,
      path: '/validar-hash/resultado',
      label: 'Resultado consulta',
      showInMenu: false,
      isPrivate: false,
      component: (props) => (
        <Suspense fallback={<div />}>
          <Signatures {...props} />
        </Suspense>
      ),
    },
    {
      id: 5,
      exact: true,
      path: '/validar-hash/nao-encontrado',
      label: 'Hash não encontrado',
      showInMenu: false,
      isPrivate: false,
      component: (props) => (
        <Suspense fallback={<div />}>
          <NoHashFound {...props} />
        </Suspense>
      ),
    },
    {
      id: 1,
      path: '/inicio',
      exact: true,
      label: 'Home',
      isPrivate: true,
      component: (props) => (
        <Suspense fallback={<div>Loading...</div>}>
          <Container>
            <AUProductTrailSuggestion />
            <div {...props}>
              <HomePage />
            </div>
          </Container>
        </Suspense>
      ),
    },
    {
      id: 2,
      productId: PRODUCT_IDS.AUTENTICA,
      path: '/',
      label: 'Autentica',
      isPrivate: true,
      exact: true,
      showInMenu: true,
      nested: [
        {
          id: 1,
          exact: true,
          path: '/solicitacoes',
          label: 'Solicitações',
          showInMenu: hasJourneyPermission,
          renderIf: () => hasJourneyPermission,
          isPrivate: true,
          icon: <FingerprintIcon />,
          component: (props) => (
            <Suspense fallback={<div />}>
              <Container>
                <AUProductTrailSuggestion />
                <RequestDataTabProvider>
                  <ValidationRequests {...props} />
                </RequestDataTabProvider>
              </Container>
            </Suspense>
          ),
          action: () => {
            sendAnalytics(EVENTS.CLICK_FLOW);
            browserHistory.push('/solicitacoes');
          },
        },
        {
          id: 2,
          exact: true,
          path: '/construtor',
          label: 'Construtor',
          showInMenu: hasAdminPermission && hasJourneyPermission,
          isPrivate: true,
          permissions: '',
          action: () => {
            sendAnalytics(EVENTS.CLICK_BUILDER);
            browserHistory.push('/construtor');
          },
          icon: (
            <SvgIcon>
              <PlataformIcon />
            </SvgIcon>
          ),
          nested: [
            {
              id: 3,
              exact: true,
              renderIf: () => hasAdminPermission && hasJourneyPermission,
              path: '/construtor',
              label: 'Construtor',
              isPrivate: true,
              icon: (
                <SvgIcon>
                  <PlataformIcon />
                </SvgIcon>
              ),
              component: () => {
                return (
                  <Suspense fallback={<div />}>
                    <Flows />
                  </Suspense>
                );
              },
            },
            {
              id: 4,
              exact: true,
              path: '/construtor/fluxos',
              label: 'Fluxos',
              showInMenu: false,
              renderIf: () => hasAdminPermission && hasJourneyPermission,
              isPrivate: true,
              icon: (
                <SvgIcon>
                  <PlataformIcon />
                </SvgIcon>
              ),
              component: () => {
                return (
                  <Suspense fallback={<div />}>
                    <BuilderV2 />
                  </Suspense>
                );
              },
            },
            {
              id: 5,
              exact: true,
              path: '/construtor/novo-fluxo',
              renderIf: () => hasAdminPermission && hasJourneyPermission,
              label: 'Novo fluxos',
              showInMenu: false,
              isPrivate: true,
              icon: (
                <SvgIcon>
                  <PlataformIcon />
                </SvgIcon>
              ),
              component: () => {
                return (
                  <Suspense fallback={<div />}>
                    <CreateNewDraft />
                  </Suspense>
                );
              },
            },
            {
              id: 6,
              exact: true,
              path: '/construtor/sandbox/:id',
              renderIf: () => hasAdminPermission && hasJourneyPermission,
              label: 'Construtor',
              showInMenu: false,
              isPrivate: true,
              icon: (
                <SvgIcon>
                  <PlataformIcon />
                </SvgIcon>
              ),
              component: () => {
                return (
                  <Suspense fallback={<div />}>
                    <BuilderSandbox />
                  </Suspense>
                );
              },
            },
            {
              id: 7,
              exact: true,
              path: '/construtor/view/:id',
              renderIf: () => hasAdminPermission && hasJourneyPermission,
              label: 'Construtor',
              showInMenu: false,
              isPrivate: true,
              icon: (
                <SvgIcon>
                  <PlataformIcon />
                </SvgIcon>
              ),
              component: () => {
                return (
                  <Suspense fallback={<div />}>
                    <BuilderSandbox />
                  </Suspense>
                );
              },
            },
          ],
        },
        {
          id: 4,
          exact: true,
          path: '/configuracoes',
          label: 'Configurações',
          icon: <SettingsIcon />,
          showInMenu: hasAdminPermission,
          isPrivate: true,
          renderIf: () => hasAdminPermission,
          permissions: '',
          action: () => {
            sendAnalytics(EVENTS.CLICK_CONFIGURATION);
            browserHistory.push('/configuracoes');
          },
          nested: [
            {
              id: 7,
              exact: true,
              path: '/configuracoes',
              label: 'Configurações',
              isPrivate: true,
              renderIf: () => hasAdminPermission,
              component: (props) => (
                <Suspense fallback={<div />}>
                  <Container>
                    <AUProductTrailSuggestion />
                    <Configuration {...props} />
                  </Container>
                </Suspense>
              ),
            },
            {
              id: 7,
              exact: true,
              path: '/configuracoes/aprovacao-automatica',
              label: 'Aprovação Automatica',
              isPrivate: true,
              renderIf: () => hasAdminPermission,
              component: (props) => (
                <Suspense fallback={<div />}>
                  <Container>
                    <AUProductTrailSuggestion />
                    <AutomaticApproval {...props} />
                  </Container>
                </Suspense>
              ),
            },
            {
              id: 8,
              exact: true,
              path: '/configuracoes/cadastro',
              label: 'Cadastro',
              isPrivate: true,
              renderIf: () => hasAdminPermission,
              component: (props) => (
                <Suspense fallback={<div />}>
                  <Container>
                    <RegistrationPage {...props} />
                  </Container>
                </Suspense>
              ),
            },
            {
              id: 9,
              exact: true,
              path: '/configuracoes/funcionalidades',
              label: 'Configuração de funcionalidades',
              isPrivate: true,
              renderIf: () => hasAdminPermission,
              component: (props) => (
                <Suspense fallback={<div />}>
                  <Container>
                    <FunctionalitiesPage {...props} />
                  </Container>
                </Suspense>
              ),
            },
          ],
        },
        {
          id: 10,
          showInMenu: true,
          action: () => {
            window.location.href = `${
              Envs().PAINEL_URL
            }/gestao-da-conta/relatorios/autentica`;
          },
          label: 'Relatórios',
          icon: <DescriptionOutlinedIcon />,
          exact: true,
          isPrivate: true,
        },
      ],
    },
  ];
};

export default getRouters;
