import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface CustomNavBarProps {
  loading?: boolean;
  logo: string;
  position?: string;
  mobileViewUpToWidth?: string;
  handleClickIconButton?: () => void;
  handleClickLogoIcon?: () => void;
}

const useStyles = makeStyles<
  Theme,
  { logoCursorPointer: boolean; position?: any }
>(() => ({
  logoContainer: {
    height: 58,
  },
  items: {
    zIndex: 'inherit',
  },
  navbarContainer: {
    top: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    minHeight: 30,
    zIndex: 90,
  },
  appBar: {
    background:
      'linear-gradient(90deg,rgba(171, 49, 228, 1) 0%,rgba(111, 19, 244, 1) 100%)',
    position: (props) => props.position ?? 'fixed',
  },
  whiteColor: {
    color: '#FFFFFF',
  },
}));

const CustomNavBar: React.FC<CustomNavBarProps> = ({
  logo,
  loading = false,
  position,
  mobileViewUpToWidth,
  handleClickIconButton = () => {},
  handleClickLogoIcon = () => {},
  children,
}) => {
  const classes = useStyles({
    logoCursorPointer: Boolean(handleClickLogoIcon),
    position,
  });

  return (
    <>
      <div className={classes.navbarContainer}>
        <AppBar
          position="fixed"
          color="inherit"
          classes={{ root: classes.appBar }}
        >
          <Toolbar>
            <Box
              className={classes.logoContainer}
              display="flex"
              alignItems="center"
            >
              <img src={logo} alt="logo do sistema" />
            </Box>
            <Box flexGrow={1} />
            <Box>{children}</Box>
          </Toolbar>
          {loading && <LinearProgress />}
        </AppBar>
      </div>
    </>
  );
};

export default CustomNavBar;
