import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '@infra/http-client';
import HttpClientAnonymous from '@infra/http-client/anonymous';
import { notify } from '@src/utils/notification';
import Upload from '@models/stores/upload';
import TemporaryLinks from '@models/upload/temporary-links';
import axios, { AxiosResponse } from 'axios';
import { GlobalStore } from '@src/models/stores/global';
import { ErrorHandled } from '@src/models/stores/error-handled';
import { AutenticaModule } from '@src/models/autentica-module';

const clientFile = HttpClient('arquivos');
const signatureClientFile = HttpClient(
  'documentos',
  process.env.REACT_APP_SIGNATURE_BASE_URL
);

const clientFileExtern = HttpClientAnonymous('arquivos');

const initialState: Upload = {
  links: [],
};

interface LinkResponse {
  url: string;
  chave: string;
}

interface TemporaryLinkResponse {
  links: LinkResponse[];
}

interface UploadJson {
  object: any;
  token: string;
  onUpload: (progress: number) => void;
}

interface UploadFile {
  autenticaModule: AutenticaModule;
  file: File;
  onUpload: (progress: number) => void;
}

const getHttpCliente = (type: string) =>
  type === 'externo' ? clientFileExtern : clientFile;

const fetchTemporaryLinkResponseHandler = (
  response: AxiosResponse<TemporaryLinkResponse>
) => {
  if (response.data && response.status === 200) {
    const { links } = response.data;
    const result = links.map<TemporaryLinks>((link) => {
      const { chave: id, url: value } = link;
      return { value, id };
    });
    return result;
  }
};

const fetchTemporaryLinks = async (
  type: string,
  quantity: number,
  token?: string
) => {
  const response = await getHttpCliente(type).get<TemporaryLinkResponse>({
    action: `obter-link-upload-${type}`,
    params: {
      ...(token && type === 'externo' && { recaptchaResponse: token }),
      quantidadeLinks: quantity,
    },
    loading: false,
  });

  return fetchTemporaryLinkResponseHandler(response);
};

export const fetchExternalTemporaryLinks = createAsyncThunk<
  TemporaryLinks[],
  { quantity: number; token: string }
>('upload/links-temporarios-externos', async ({ quantity, token }) => {
  const links = await fetchTemporaryLinks('externo', quantity, token);
  if (links && links.length > 0) {
    return links;
  }
  throw new Error(`Ocorreu um erro ao buscar os links temporarios.`);
});

export const fetchInternalTemporaryLinks = createAsyncThunk<
  TemporaryLinks[],
  number
>('upload/links-temporarios-internos', async (quantity) => {
  const links = await fetchTemporaryLinks('interno', quantity);
  if (links && links.length > 0) {
    return links;
  }
  throw new Error(`Ocorreu um erro ao buscar os links temporarios.`);
});

export const fetchSignatureTemporaryLinks = createAsyncThunk<
  TemporaryLinks[],
  number
>('upload/assinaturas/links-temporarios-temporario', async (quantity) => {
  const response = await signatureClientFile.get<TemporaryLinkResponse>({
    action: `obter-link-upload-temporario`,
    params: {
      quantidadeLinks: quantity,
    },
    loading: false,
  });

  const links = fetchTemporaryLinkResponseHandler(response);

  if (links && links.length > 0) {
    return links;
  }
  throw new Error(`Ocorreu um erro ao buscar os links temporarios.`);
});

export const uploadJson = createAsyncThunk<
  string,
  UploadJson,
  { rejectValue: ErrorHandled }
>(
  'upload/json',
  async (
    { object, onUpload, token },
    { getState, dispatch, rejectWithValue }
  ) => {
    const links = [...(getState() as GlobalStore).upload.links];
    if (links.length === 0) {
      await dispatch(fetchInternalTemporaryLinks(5));
      const result = await dispatch(uploadJson({ object, onUpload, token }));
      const key: string = (result?.payload as string) ?? '';
      return key;
    }
    dispatch(removeValidLink());
    const link = links.pop();
    if (link) {
      const blob = new Blob([JSON.stringify(object)], {
        type: 'application/json',
      });
      const file = new File([blob], `${link.id}.json`, {
        type: 'application/json',
      });
      const response = await axios.put(link.value, file, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        onUploadProgress: (event) => {
          if (event.total) {
            const progress: number = Math.round(
              (event.loaded * 100) / event.total
            );
            onUpload(progress);
          }
        },
      });
      if (response.status === 200) return link.id;
      else
        return rejectWithValue({
          errorMessage: `Ocorreu um erro ao realizar o upload.`,
        });
    }
    return '';
  }
);

export const uploadFile = createAsyncThunk<
  string,
  UploadFile,
  { rejectValue: ErrorHandled }
>(
  'upload/file',
  async (
    { file, onUpload, autenticaModule },
    { getState, dispatch, rejectWithValue }
  ) => {
    const links = [...(getState() as GlobalStore).upload.links];

    if (links.length === 0) {
      if (autenticaModule === AutenticaModule.Jorneys)
        await dispatch(fetchInternalTemporaryLinks(5));
      else await dispatch(fetchSignatureTemporaryLinks(5));
      const result = await dispatch(
        uploadFile({ file, onUpload, autenticaModule })
      );
      const key: string = (result?.payload as string) ?? '';
      return key;
    }
    dispatch(removeValidLink());
    const link = links.pop();
    if (link) {
      try {
        const response = await axios.put(link.value, file, {
          headers: {
            'Content-Type':
              autenticaModule === AutenticaModule.Jorneys
                ? 'application/octet-stream'
                : 'application/pdf',
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const progress: number = Math.round(
                (event.loaded * 100) / event.total
              );
              onUpload(progress);
            }
          },
        });
        if (response.status === 200) return link.id;
        else
          return rejectWithValue({
            errorMessage: `Ocorreu um erro ao realizar o upload.`,
          });
      } catch {
        return rejectWithValue({
          errorMessage: `Ocorreu um erro inesperado`,
        });
      }
    }
    return '';
  }
);

export const uploadSlice = createSlice({
  name: 'builder',
  initialState: { ...initialState },
  reducers: {
    removeValidLink: (state) => {
      state.links.pop();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchExternalTemporaryLinks.fulfilled,
      (state, { payload }) => {
        state.links = payload;
      }
    );
    builder.addCase(
      fetchInternalTemporaryLinks.fulfilled,
      (state, { payload }) => {
        state.links = payload;
      }
    );
    builder.addCase(
      fetchExternalTemporaryLinks.rejected,
      (state, { payload, error }) => {
        notify(error?.message ?? 'Ocorreu um erro inesperado');
      }
    );
    builder.addCase(uploadFile.fulfilled, (state, { payload }) => {
      notify('Upload realizado com sucesso');
    });
    builder.addCase(uploadFile.rejected, (state, { payload, error }) => {
      notify(payload?.errorMessage ?? 'Ocorreu um erro inesperado');
    });
    builder.addCase(uploadJson.rejected, (state, { payload, error }) => {
      notify(payload?.errorMessage ?? 'Ocorreu um erro inesperado');
    });
    builder.addCase(
      fetchSignatureTemporaryLinks.fulfilled,
      (state, { payload }) => {
        state.links = payload;
      }
    );
  },
});

export const { removeValidLink } = uploadSlice.actions;
export default uploadSlice.reducer;
