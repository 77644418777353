import LoadingButton from '@src/components/LoadingButton';
import { Type } from '@src/models/dynamic-field';
import { iCompanyRegistration } from '@src/models/stores/registration/company-registration';
import { AppDispatch } from '@src/stores';
import { captureTypes } from '@src/utils/DynamicFields/fields';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { dispatchFetchConfigSms, postCompany } from '../../stores/registration';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { AUBoxWrapper, AULoadingManager } from '@assertiva/assertiva-ui';
import { useConfigSmsFeatureFlag } from '@src/hook/useConfigSmsFeatureFlag';

const RegistrationPage = () => {
  const defaultValues = {
    sms: '',
  };
  const [preloadedName, setPreloadedName] = useState<string>('');

  const dispatch: AppDispatch = useDispatch();
  const Field = captureTypes['string'];
  const { control, handleSubmit, errors, watch, reset } =
    useForm<iCompanyRegistration>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues,
    });
  const watchAllFields = watch(['sms']);

  const disable = useMemo(() => {
    const { sms } = watchAllFields;
    const watchBaseFields = { sms };
    const fields = Object.keys(watchBaseFields);
    const result =
      fields.every(
        (item) =>
          watchBaseFields[item] !== undefined &&
          watchBaseFields[item].length > 0
      ) && preloadedName !== sms;
    return result;
  }, [watchAllFields, preloadedName]);

  const onSubmit = async (values: iCompanyRegistration) => {
    try {
      await dispatch(postCompany(values));
      setPreloadedName(values.sms);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchCompanyNameSms = async () => {
      try {
        AULoadingManager.show();
        const companyName = await dispatchFetchConfigSms(dispatch);
        reset({ sms: companyName });
        setPreloadedName(companyName);
      } catch (err) {
        console.error(err);
      } finally {
        AULoadingManager.close();
      }
    };

    fetchCompanyNameSms();
  }, [dispatch, reset]);

  const { shouldHideConfigSms } = useConfigSmsFeatureFlag();

  return (
    <AUBoxWrapper>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Paper style={{ width: '100%', maxWidth: 640 }}>
          <Box p={4} width="100%">
            <Box
              alignItems="center"
              flexDirection="column"
              display="flex"
              width="100%"
            >
              <Box pb={3} display="flex" flexDirection="column" width="100%">
                <Typography variant="h6">Cadastro de SMS</Typography>
                <Box color="#666666">
                  <Typography variant="body2" color="inherit">
                    Adicione o nome da empresa que aparecerá no SMS enviado para
                    o seu cliente.
                  </Typography>
                </Box>
                <Grid container spacing={3} style={{ marginTop: '24px' }}>
                  <Grid item xs={12}>
                    <Field
                      required
                      id="sms-filter-id"
                      name="sms"
                      fullWidth
                      maxLength="45"
                      control={control}
                      errors={errors}
                      type={Type.String}
                      label="Nome que aparecerá no envio do SMS"
                      textfieldInputProps={{
                        InputProps: { disabled: shouldHideConfigSms },
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box display="flex" justifyContent="end">
              <LoadingButton
                onClick={handleSubmit(onSubmit)}
                style={{ color: 'white' }}
                variant="contained"
                color="primary"
                disabled={!disable}
              >
                Cadastrar
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </AUBoxWrapper>
  );
};

export default RegistrationPage;
