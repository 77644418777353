import { styled, Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface ButtonCustomProps extends ButtonProps {
  theme: Theme;
  backgroundcolor?: string;
  fontcolor?: string;
}

const backgroundColorType = (props: ButtonCustomProps) => {
  if (props.variant === undefined || props.variant === 'outlined') {
    return 'none';
  }

  if (props.variant === 'contained') {
    return props.backgroundcolor
      ? props.backgroundcolor
      : props.theme.palette.primary.main;
  }
  return props.theme.palette.primary.main;
};

const fontColorType = (props: ButtonCustomProps) => {
  if (props.variant === undefined || props.variant === 'outlined') {
    return props.theme.palette.primary.main;
  }

  if (props.variant === 'contained') {
    return props.fontcolor ? props.fontcolor : 'white';
  }
  return 'white';
};

const styles = (props: ButtonCustomProps) => {
  const fontColor = fontColorType(props);
  const background = backgroundColorType(props);

  return {
    backgroundColor: background,
    '&:hover': {
      backgroundColor: background,
    },
    color: fontColor,
    '&.Mui-disabled': {
      color: fontColor,
      backgroundColor: background,
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    '&.Mui-disabled:hover': {
      backgroundColor: background,
    },
  };
};

export const ButtonCustom = styled(Button)(styles);
